import React, { Fragment, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import petitionGet from "../../../services/petitionGet";
import PageTitle from "../../layouts/PageTitle";
import AddUserModal from "./addUserModal";
import EditUserModal from "./EditUser/editUserModal";
import Loading from "../Loading/Loading";
import CustomTable from "../../../utils/CustomTable/CustomTable";
import EditIcon from "@material-ui/icons/Edit";

const Users = () => {
  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState(null);

  const drop = (element) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="btn btn-primary tp-btn-light sharp i-false"
        >
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            href="#"
            onClick={() => {
              setModal(true);
              setInfoModal(element);
            }}
          >
            Edit
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const [users, setUsers] = useState([]);

  const activeEditModal = (element) => {
    setModal(true);
    setInfoModal(element);
  }

  const petition = () => {
    setLoading(true);
    petitionGet("contacts")
      .then(({ data: { result } }) => {
        result.forEach((user) => {
          user.name = user.firstname + " " + user.lastname;
        });
        setUsers(result);
        setDataTable({
          title: [
            { title: "Name", key: "name" },
            { title: "Email", key: "email" },
            { title: "Timezone", key: "time_zone" },
          ],
          content: [...result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <EditIcon />,
                name: "Edit",
                handleClick: activeEditModal,
              },
            ],
          },
          search: {
            label: "search user",
          },
          addButton: {
            label: "Add User",
            handleClick: () => {
              setModalAdd(true);;
            },
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reloadData = () => {
    petition();
  };

  useEffect(() => {
    petition();
  }, []);

  return (
    <Fragment>
      <PageTitle activeMenu="Admin" motherMenu="Users" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {modal && (
        <EditUserModal
          reloadData={reloadData}
          setModal={setModal}
          setInfoModal={setInfoModal}
          infoModal={infoModal}
          modal={modal}
        />
      )}
      {modalAdd && (
        <AddUserModal
          reloadData={reloadData}
          setModal={setModalAdd}
          modal={modalAdd}
        />
      )}
      <div className="row">
        {loading ? (
          <Loading />
        ) : (
          <CustomTable data={dataTable} noClass={true} />
          // <div className="col-lg-12 text-end">
          //   <button
          //     className="btn btn-primary my-2 mb-4"
          //     onClick={() => {
          //       setModalAdd(true);
          //     }}
          //   >
          //     Add User
          //   </button>
          //   <div className="card">
          //     <div className="card-body ">
          //       <div className="table-responsive">
          //         <table className="table mb-0">
          //           <thead>
          //             <tr>
          //               <th className="text-start">Name</th>
          //               <th className="text-start">Email</th>
          //               <th className="text-start">Timezone</th>
          //               <th></th>
          //               <th className="customer_shop">
          //                 <div className="form-check custom-checkbox mx-2"></div>
          //               </th>
          //             </tr>
          //           </thead>
          //           <tbody id="customers">
          //             {users.map((user, i) => (
          //               <tr key={i} className="btn-reveal-trigger">
          //                 <td className="py-2 text-start">
          //                   <div className="media d-flex align-items-center">
          //                     <div className="media-body">
          //                       <h5 className="mb-0 fs--1">
          //                         {user.firstname} {user.lastname}
          //                       </h5>
          //                     </div>
          //                   </div>
          //                 </td>
          //                 <td className="py-2 text-start">
          //                   <a href="mailto:ricky@example.com">{user.email}</a>
          //                 </td>
          //                 <td className="py-2 text-start">{user.time_zone}</td>
          //                 <td className="py-2 text-right">{}</td>
          //                 <td className="customer_shop_single">
          //                   {/* {chack(1)} */}
          //                   {drop(user)}
          //                 </td>
          //               </tr>
          //             ))}
          //           </tbody>
          //         </table>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        )}
      </div>
    </Fragment>
  );
};

export default Users;
