import React from "react";
import { Link } from "react-router-dom";

const Error401 = () => {
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-5">
            <div className="form-input-content text-center error-page">
              {/* <h1 className="error-text font-weight-bold">401</h1> */}
              {/* <h4>
                <i className="fa fa-thumbs-down text-danger" /> Bad Request
              </h4> */}
              <p>
                The user you are currently logged in with does not have a
                VOICE360/CRM360 License assigned. Please purchase a license and
                assign a license to this user
              </p>
              <div>
                <Link className="btn btn-primary" to="/dashboard">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error401;
