/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  let { admin_group } = props.profile.auth.auth;
  let { billing_group } = props.profile.auth.auth;

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    /*   var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast); */
  }, []);

  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  //let scrollPosition = useScrollPosition();

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  // let deshBoard = [
  //     "",
  //     "dashboard-dark",
  //     "kanban",
  //     "clients",
  //     "project-details",
  //     "messages",
  //     "latest-activity",
  //     "task",
  //     "apps",
  //   ],
  //   app = [
  //     "profile",
  //     "post-details",
  //     "app-calender",
  //     "email-compose",
  //     "email-inbox",
  //     "email-read",
  //     "ecom-product-grid",
  //     "ecom-product-list",
  //     "ecom-product-order",
  //     "ecom-checkout",
  //     "ecom-invoice",
  //     "ecom-customers",
  //     "post-details",
  //     "ecom-product-detail",
  //   ],
  //   services = ["Services"],
  //   widget = ["Billing"],
  //   tickets = ["tickets"],
  //   users = ["Users"];
  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${path === "profile" ? "mm-active" : ""}`}>
            <Link to="profile" className="ai-icon">
              {/* <i className="fas fa-user-check"></i> */}
              <i className="fas fa-address-card"></i>
              <span className="nav-text">Profile</span>
            </Link>
          </li>
          <li className={`${path === "apps" ? "mm-active" : ""}`}>
            <Link to="apps" className="ai-icon">
              {/* <i className="fas fa-info-circle"></i> */}
              <svg
                className="gb_Ue"
                focusable="false"
                fill="gray"
                viewBox="0 0 24 24"
              >
                <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
              </svg>
              <span style={{ marginLeft: "10px" }} className="nav-text">
                Apps
              </span>
            </Link>
          </li>
          <li className={`${path === "services" ? "mm-active" : ""}`}>
            <Link to="services" className="ai-icon">
              <i className="fas fa-solid fa-check"></i>
              <span className="nav-text">Services</span>
            </Link>
          </li>
          {billing_group && (
            <li
              style={{ marginLeft: "3px" }}
              className={`${path === "Billing" ? "mm-active" : ""}`}
            >
              <Link to="Billing" className="ai-icon">
                {/* <i className="fas fa-file-alt"></i> */}
                <i className="fas fa-dollar-sign"></i>
                <span style={{ marginLeft: "6px" }} className="nav-text">
                  Billing
                </span>
              </Link>
            </li>
          )}
          {admin_group && (
            <li className={`${path === "Users" ? "mm-active" : ""}`}>
              <Link to="Users" className="ai-icon">
                <i className="fas fa-users"></i>
                <span className="nav-text">Users</span>
              </Link>
            </li>
          )}

          {/* <li className={`${tickets.includes(path) ? "mm-active" : ""}`}>
            <Link to="tickets" className="ai-icon">
              <i className="fas fa-table"></i>
              <span className="nav-text">Support</span>
            </Link>
          </li> */}
        </MM>

        <div className="copyright">
          <p>
            <strong>ITP</strong> © 2022 All Rights Reserved
          </p>
          {/*  <p className="fs-12">
            Made with <span className="heart"></span> by Duwan :)
          </p> */}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(SideBar));
