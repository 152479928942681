import React, { useState, useEffect } from "react";

import { Button, Modal } from "react-bootstrap";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export default function ModalUtility({ modal, setModal, redirectUser }) {
  useEffect(() => {
    console.log(modal);
  }, []);

  return (
    <Modal style={{ marginTop: "10rem" }} show={modal} className="info">
      <Modal.Body style={{ textAlign: "center", padding: "2rem" }}>
        <ErrorOutlineIcon style={{ fontSize: "5rem", marginBottom: "2rem" }} />
        <p
          style={{
            fontSize: "18px",
            textAlign: "center",
            paddingBottom: "1rem",
          }}
        >
          {" "}
          The link you have clicked on has expired. Please click below to resend
          a new email containing a refreshed link.{" "}
        </p>
        <Button
          style={{ fontSize: "18px", padding: "0.7rem 3rem" }}
          onClick={() => redirectUser()}
          color="primary"
        >
          Reset Link
        </Button>
      </Modal.Body>{" "}
    </Modal>
  );
}
