import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import jwt_decode from "jwt-decode";
import config from "../config.json";
import petitionGet from "./petitionGet";

const json = require("../config.json");

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const urlBase = config.prod ? config.urlBase.prod : config.urlBase.dev;
  const postData = {
    username: email,
    password: password,
  };
  return axios.post(`${urlBase}/crm/auth/login`, postData);
}

export function formatError(errorResponse) {
  if (errorResponse.message === "Invalid credentials") {
    swal("Oops", "Invalid credentials", "error");
  }
  // switch (errorResponse.message) {
  //   case "":
  //     swal("Oops", "Email already exists", "error");
  //     break;
  //   case "EMAIL_NOT_FOUND":
  //     swal("Oops", "Email not found", "error", { button: "Try Again!" });
  //     break;
  //   case "INVALID_PASSWORD":
  //     swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
  //     break;
  //   case "USER_DISABLED":
  //     return "User Disabled";

  //   default:
  //     return "";
  // }
}

export function saveTokenInLocalStorage(tokenDetails) {
  let decodedToken = jwt_decode(tokenDetails.access_token);

  decodedToken.expireDate = new Date(new Date().getTime() + decodedToken.exp);
  decodedToken.token = tokenDetails.access_token;
  decodedToken.access_token = tokenDetails.access_token;

  localStorage.setItem(
    "userDetails",
    JSON.stringify({ ...decodedToken, ...tokenDetails })
  );
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export async function checkAutoLogin(dispatch, history) {
  const params = new URL(document.location).searchParams;
  const token = params.get("token");
  const url = json.prod
    ? `${json.portal.prod}/login?redirect=${json.pbx.prod}`
    : `${json.portal.dev}/login?redirect=${json.pbx.dev}`;

  if (token) {
    await petitionGet("refresh", token)
      .then((result) => {
        console.log(result.data);
        saveTokenInLocalStorage(result.data.result);
      })
      .catch((err) => {
        window.location.href = url;
      });
  }
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  // console.log(tokenDetailsString);
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(logout(history));
    return;
  }

  tokenDetails.access_token = tokenDetails?.token;
  tokenDetails = {
    ...tokenDetails,
    ...tokenDetails.user_contact,
    account: tokenDetails.account,
  };
  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, history);
}
