import React, { useEffect } from "react";
import { Pagination } from "react-bootstrap";

// size =  sm lg null => null es el default

export default function PaginationITP({ size, pages,active,setActive }) {

  useEffect(() => {}, []);

  const items = () => {
    let items = [];
    items.push(
        <Pagination.Item   onClick={()=>active>1?setActive(active-1):setActive(1)}>
           {"<"}
        </Pagination.Item>
      );
    items.push(
      <Pagination.Item  active={active === 1} onClick={()=>setActive(1)}>
        {1}
      </Pagination.Item>
    );
    for (let number = 2; number <= pages - 1; number++) {
      if (number + 2 >= active && number - 2 <= active) {
        items.push(
          <Pagination.Item key={number} active={number === active} onClick={()=>setActive(number)}>
            {number}
          </Pagination.Item>
        );
      }
    }
    items.push(
      <Pagination.Item  active={active === pages} onClick={()=>setActive(pages)}>
        {pages}
      </Pagination.Item>
    );
    items.push(
        <Pagination.Item   onClick={()=>active<pages?setActive(active+1):setActive(pages)}>
          {">"}
        </Pagination.Item>
      );
    return items
  };

  return (
    <>
      <Pagination size={size}>{items()}</Pagination>
    </>
  );
}
