import React, {Fragment, useState, useRef, useEffect} from "react";
import {Link} from "react-router-dom";
import {ToastContainer} from "react-toastify";

import PageTitle from "../../../../layouts/PageTitle";
import AddTickets from "./AddTickets";
import Tickets from "./Tickets";
import ViewTicket from "./ViewTicket";

const Inbox = () => {
  const [step, setStep] = useState(1);

  const stepName = {
    1: "All Tickets",
    2: "Create Ticket",
    3: "Ticket Details",
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={stepName[step]}
        motherMenu="Tickets"
        pageContent="Tickets"
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              {step === 1 && <Tickets setStep={setStep} />}
              {step === 2 && <AddTickets setStep={setStep} />}
              {step === 3 && <ViewTicket setStep={setStep} />}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Inbox;
