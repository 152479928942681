import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import petitionGet from "../../../services/petitionGet";
import { sendToastError } from "../../../utils/toastAlert";
import "../../../css/style.css";
import pay360Image from "../../../images/paylink360icon.svg"
import { Spinner } from "react-bootstrap";

//Images
import ser from "../../../images/serv.png";
// import CRM from "../../../images/CRM.png";
// import '../../../scss/pages/_clients.scss'
// import { type } from "os";

const Clients = () => {
  const [contacts, setContacts] = useState([]);
  const [loaading, setLoading] = useState(true)


  useEffect(() => {
    setContacts([]);
    petitionGet("services")
      .then((result) => {
        let token = JSON.parse(localStorage.getItem("userDetails")).token;
        let json = require("../../../config.json");
        let linkPbx = json.prod ? json.pbx.prod : json.pbx.dev;
        let payLink = json.prod ? json.payLink.prod : json.payLink.dev;

        let apps = [];
        result.data.result.forEach((element) => {
          if (element.item_type === "crm360") {
            element.route = `https://app.dev.crm360.app/accounts/login?token=${token}`;
          } else if (element.item_type === "itp_voice") {
            // element.route = `${linkPbx}/login?token=${token}&&v2=${element.api_id}`;
            element.route = `${window.location.host === "localhost:3000"
              ? `http://localhost:3001/login?token=${token}&&v2=${element.api_id}`
              : `${linkPbx}/login?token=${token}&&v2=${element.api_id}`
              }`;
            // element.route = `http://localhost:3001/login?token=${token}&&v2=${element.api_id}`;
            element.prod_description = "VOICE360";
          } else if (element.item_type === "office365") {
            element.prod_description = "Microsoft 365 Business Standard";
          } else if (element.item_type === "PayLink360") {
            element.route = `${window.location.host === "localhost:3000"
              ? `http://localhost:3002/login?token=${token}&&v2=${element.api_id}`
              : `${payLink}/login?token=${token}&&v2=${element.api_id}`
              }`;
          }

          apps.push(element);
        });
        setContacts(result.data.result);
        setLoading(false)
      })
      .catch((err) => sendToastError());
  }, []);


  return (
    <>
      {loaading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}><Spinner
        animation="grow"
        style={{
          marginTop: "10vh",
          marginBottom: "10vh",
          height: "10vh",
          width: "10vh",
        }}
      /> </div> : <>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="input-group contacts-search mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Search here..."
            />
            <span className="input-group-text">
              <Link to={"#"}>
                <i className="flaticon-381-search-2"></i>
              </Link>
            </span>
          </div>
        </div>
        <div className="row ">
          <div className="col-xl-12">
            <div className="row">
              {contacts.map((contact) => {
                if (contact.product.item_type === "itp_voice") {
                  return (
                    <div
                      onClick={() => {
                        window.location.href = contact.route;
                      }}
                      className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6 items"
                      key={contact.api_id}
                      style={{ cursor: "pointer" }}
                    >
                      <div className=" style-apps card contact-bx item-content cartService app-dark">
                        <div className="card-body user-profile pb-0 ">
                          <div className="d-flex flex-column py-3 align-items-center">
                            <div className="image-bx">
                              <img src={ser} alt="" className="rounded-circle" />
                            </div>
                            <div className="ms-3 text-center">
                              <h4 className="fs-18 font-w600">{contact.title}</h4>
                              <span className="text-primary d-block">
                                {"VOICE360"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (contact.product.item_type === "PayLink360") {
                  return (
                    <div
                      onClick={() => {
                        window.location.href = contact.route;
                      }}
                      className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6 items"
                      key={contact.api_id}
                      style={{ cursor: "pointer" }}
                    >
                      <div className=" style-apps card contact-bx item-content cartService app-dark">
                        <div className="card-body user-profile pb-0 ">
                          <div className="d-flex flex-column py-3 align-items-center">
                            <div className="image-bx">
                              <img src={pay360Image} alt="" className="rounded-circle" />
                            </div>
                            <div className="ms-3 text-center">
                              <h4 className="fs-18 font-w600">{contact.title}</h4>
                              <span className="text-primary d-block">
                                {"PayLink360"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </div>
        </div>
      </>}


    </>
  );
};
export default Clients;
