import React, { Fragment, useState, useEffect, useContext } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import NavTabCustom from "../../NavTabCustom/NavTabCustom";
import ProfileSettings from "./Tabs/ProfileSettings";
import ChangePassword from "./Tabs/ChangePassword";

const AppProfile = (props) => {
  const options = [
    {
      title: "Profile Settings",
      component: <ProfileSettings props={props} />,
    },
    {
      title: "Change Password",
      component: <ChangePassword />,
    },
  ];

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-12 ">
          <div className="d-flex row flex-wrap custom-tab-1">
            <Container fluid>
              <NavTabCustom options={options} />
            </Container>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(AppProfile));
