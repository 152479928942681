import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import petitionGet from "../../../../services/petitionGet";
import Loading from "../../Services/Loading";

function Orders(props) {
  // const motherChackBox = document.querySelector(".product_order_single");
  // const chackbox = document.querySelectorAll(".product_order");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // const chackboxFun = (type) => {
  //   for (let i = 0; i < chackbox.length; i++) {
  //     const element = chackbox[i];
  //     if (type === "all") {
  //       if (motherChackBox.checked) {
  //         element.checked = true;
  //       } else {
  //         element.checked = false;
  //       }
  //     } else {
  //       if (!element.checked) {
  //         motherChackBox.checked = false;
  //         break;
  //       } else {
  //         motherChackBox.checked = true;
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    let { account } = props.profile.auth.auth;
    setLoading(true);
    petitionGet("billing_payments", account.billing_api_id)
      .then(({ data: { result } }) => {
        let paids = [];

        result.forEach(
          (element) => element.payment_status === "Paid" && paids.push(element)
        );
        setData(paids);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="col-12 col-lg-9">
      {loading ? (
        <Loading />
      ) : (
        <div className="card">
          <div className="card-body " style={{ padding: "1.25rem" }}>
            <div className="table-responsive">
              <table className="table table-sm mb-0 table-responsive-lg ">
                <thead>
                  <tr>
                    <th className="align-middle">
                      {/* <div className="form-check custom-checkbox ms-1">
                      <input
                        type="checkbox"
                        onClick={() => chackboxFun("all")}
                        className="form-check-input  product_order_single"
                        id="checkAll"
                      />
                      <label className="form-check-label" htmlFor="checkAll" />
                    </div> */}
                    </th>
                    <th className="align-middle pr-7">Date</th>
                    <th className="align-middle text-right">Status</th>
                    <th className="align-middle text-right">Amount</th>
                    <th className="align-middle text-right">Credit Card</th>
                  </tr>
                </thead>
                <tbody id="orders">
                  {data.map((element, i) => (
                    <tr key={i} className="btn-reveal-trigger">
                      <td className="py-2">
                        {/* <div className="form-check custom-checkbox checkbox-success">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun()}
                          className="form-check-input product_order"
                          id="checkbox"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkbox"
                        />
                      </div> */}
                      </td>
                      <td className="py-2">{element.created.substr(0, 10)}</td>

                      <td className="py-2 text-right">
                        <span className="badge badge-success">
                          {element.payment_status}
                          <span className="ms-1 fa fa-check" />
                        </span>
                      </td>
                      <td className="py-2 text-right">${element.amount}</td>
                      <td className="py-2 text-right">
                        {element.last_4_of_credit_card === null
                          ? "***********"
                          : "***********" + element.last_4_of_credit_card}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(Orders));
