import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import petitionPatch from "../../../../../services/petitionPatch";
import {
  sendToastError,
  sendToastSuccess,
} from "../../../../../utils/toastAlert";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorNoData, setErrorNoData] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    password: "",
    confirm_new_password: "",
  });

  const changeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setErrorPassword(false);
    setErrorNoData(false);
    console.log({ ...form, [e.target.name]: e.target.value });
  };

  const changePassword = () => {
    if (form.password === "" && form.confirm_new_password === "")
      return setErrorNoData(true);
    if (form.password !== form.confirm_new_password)
      return setErrorPassword(true);

    setLoading(true);
    petitionPatch("myprofile", { password: form.password })
      .then(({ data: result }) => {
        console.log(result);
        // localStorage.setItem("userDetails", JSON.stringify(result));
        sendToastSuccess();
        setLoading(false);
      })
      .catch((error) => {
        sendToastError();
        console.log(error);
      });
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body">
            <div className="settings-form">
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label htmlFor="password" className="text-label">
                    New password
                  </label>
                  <div className="input-group transparent-append mb-2">
                    <span className="input-group-text">
                      {" "}
                      <i className="fa fa-lock" />{" "}
                    </span>

                    <input
                      type={`${showPassword ? "text" : "password"}`}
                      className={`form-control ${
                        errorNoData && "form-control-error"
                      } `}
                      id="password"
                      name="password"
                      onChange={(e) => changeForm(e)}
                      placeholder=""
                    />

                    <div
                      className="input-group-text show-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {" "}
                      {showPassword === true ? (
                        <i className="fa fa-eye" />
                      ) : (
                        <i className="fa fa-eye-slash" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label htmlFor="confirm_new_password" className="text-label">
                    Confirm new password
                  </label>
                  <div className="input-group transparent-append mb-2">
                    <span className="input-group-text">
                      {" "}
                      <i className="fa fa-lock" />{" "}
                    </span>

                    <input
                      type={`${showPassword ? "text" : "password"}`}
                      className={`form-control ${
                        errorNoData && "form-control-error"
                      } `}
                      name="confirm_new_password"
                      id="confirm_new_password"
                      onChange={(e) => changeForm(e)}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              {errorPassword && (
                <p className="text-danger">Passwords are not the same</p>
              )}
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => changePassword()}
              >
                {loading && (
                  <Spinner
                    style={{ marginRight: "0.5em" }}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
