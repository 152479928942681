import { toast } from "react-toastify";
export const sendToastSuccess = (text) => {
  toast.success(`✔️ ${text ? text : "Success"} !!`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const sendToastError = (text) => {
  toast.error(`✔️ ${text ? text : "Error"} !! `, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
