import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import petitionGet from "../../../../services/petitionGet";
import { sendToastError } from "../../../../utils/toastAlert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loading from "../../Services/Loading";

function Orders(props) {
  let { account } = props.profile.auth.auth;

  const [statements, setStatements] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    petitionGet("viewStaments", account.billing_api_id)
      .then(({ data: { result } }) => {
        setStatements(result);
        setLoadingData(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const Download = (val, name) => {
    petitionGet("getStaments", val)
      .then(({ data: { result } }) => {
        let link = document.createElement("a");
        link.download = `${name}.pdf`;
        link.href = `data:application/pdf;base64,${result}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        setLoading(null);
      })
      .catch((err) => sendToastError());
  };

  return (
    <div className="col-lg-9 col-12">
      {loadingData ? (
        <Loading />
      ) : (
        <div className="card">
          <div className="card-body " style={{ padding: "1.25rem" }}>
            <div className="table-responsive">
              <table className="table table-sm mb-0 table-responsive-lg ">
                <thead>
                  <tr>
                    <th className="align-middle ">Date</th>
                    <th className="align-middle ">Download Pdf</th>
                  </tr>
                </thead>
                <tbody id="orders">
                  {statements.map((element, i) => (
                    <tr key={i} className="btn-reveal-trigger">
                      <td className="py-2">{element.created.substr(0, 10)}</td>
                      <td className="py-2 text-right">
                        <Button
                          onClick={() => {
                            /* this.toggle(); */
                            setLoading(i);
                            Download(
                              element._id,
                              element.created.substr(0, 10)
                            );
                          }}
                          color="primary"
                          disabled={loading === i}
                        >
                          {loading === i && (
                            <Spinner
                              style={{ marginRight: "0.5em" }}
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Download PDF
                          {/* {loading !== i ? "Download PDF" : "Loading..."} */}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(Orders));
