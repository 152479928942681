import { lazy, Suspense, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { logout } from "./store/actions/AuthActions";
import ChangePassword from "./jsx/pages/Authentication/ChangePassword";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import config from "./config.json";

// const Impersonate = lazy(() => import("./jsx/pages/impersonate.js"));
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

const theme = createTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that accesses the theme
  }
});

function App(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    const pathname = window.location.pathname;
    // console.log(pathname);
    if (pathname !== "/page-change-password") {
      checkAutoLogin(dispatch, props.history);
    }
  }, [dispatch, props.history]);

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    var logoutVar = params.get("logout");
    if (logoutVar === "true") dispatch(logout(props.history));
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/login" && props.isAuthenticated) {
      const params = new URL(document.location).searchParams;
      const token = params.get("token");
      const redirect = params.get("redirect");

      if (!redirect) {
        if (token)
          // window.location.href = `https://app${
          //   config.prod ? "" : ".dev"
          // }.voice360.app/login?token${token}`;
          props.history.push("/apps");
        else props.history.push("/apps");
      } else {
        const token = JSON.parse(localStorage.getItem("userDetails")).token;
        window.location.href = `https://app${
          config.prod ? "" : ".dev"
        }.voice360.app/login?token=${token}`;
      }
    }
  }, [props.isAuthenticated]);

  let routes = (
    <Switch>
      {/* <Route path="/impersonate" component={Impersonate}/> */}
      <Route path="/page-change-password" component={ChangePassword} />
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
    </Switch>
  );

  if (props.isAuthenticated) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <Index />
          </Suspense>
        </ThemeProvider>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
