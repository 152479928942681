import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Switch from "react-switch";
import petitionPatch from "../../../../services/petitionPatch";
import { sendToastError, sendToastSuccess } from "../../../../utils/toastAlert";
import { connect } from "react-redux";
import visaLogo from "../../../../images/visa.png";
import masterLogo from "../../../../images/masterCard.png";
import amxLogo from "../../../../images/americanExpress.png";
import discoverLogo from "../../../../images/discover.png";
import Loading from "../../Loading/Loading";

import { withRouter } from "react-router-dom";
import petitionGet from "../../../../services/petitionGet";
import petitionDelete from "../../../../services/petitionDelete";
function AddCardModal({ setModal, modal, profile }) {
  const [checked, setChecked] = useState(false);
  const [addCC, setAddCC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState({});
  const [reload, setReload] = useState(true);

  let { account } = profile.auth.auth;

  const {
    register,
    // control,
    handleSubmit,
    // setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      number: "",
      exp_month: "1",
      exp_year: "2022",
      type: "amex",
    },
  });

  const handleSubmitSave = (dataForm) => {
    let data = [
      account.billing_api_id,
      { credit_card_info: { ...dataForm }, automatic_payments: true },
    ];
    setLoading(true);

    petitionPatch("NewCreditcard", { ...data })
      .then((result) => {
        setAddCC(false);
        sendToastSuccess();
        setReload(true);
      })
      .catch((err) => sendToastError())
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (reload) {
      setReload(false);
      setCardData({});
      setLoading(true);
      petitionGet("account", account.billing_api_id)
        .then(({ data: { result } }) => {
          setChecked(result.automatic_payments);
          setCardData(result);
          setLoading(false);
        })
        .catch((error) => sendToastError());
    }
  }, [reload]);

  const handleDeleteCreditCard = () => {
    petitionDelete("deletecc", account.billing_api_id)
      .then(({ data: { result } }) => {
        let data = [account.billing_api_id, { automatic_payments: false }];

        petitionPatch("NewCreditcard", data)
          .then(({ data: { result } }) => {
            sendToastSuccess();
            setReload(true);
          })
          .catch((error) => sendToastError());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeCheck = () => {
    if (!cardData.credit_card_info.type) {
      setAddCC(true);
      setChecked(!checked);
    } else {
      let data = [account.billing_api_id, { automatic_payments: !checked }];

      petitionPatch("NewCreditcard", data)
        .then(({ data: { result } }) => {
          sendToastSuccess();
          setReload(true);
          setChecked(!checked);
        })
        .catch((error) => sendToastError());
    }
  };

  return (
    <Modal className="fade" show={modal} centered>
      <Modal.Header>
        <Modal.Title>Automatic Payments</Modal.Title>
        <Button
          onClick={() => setModal(false)}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : addCC ? (
          <form className="mt-1" onSubmit={handleSubmit(handleSubmitSave)}>
            <div style={{ paddingTop: "10px" }} className="automatic-payment">
              <span
                style={{
                  paddingRight: "60px",
                }}
              >
                First Name:
              </span>
              <input
                {...register("first_name", { required: true })}
                type="text"
                className={`form-control-automatic-payment ${
                  errors.first_name && "form-control-error"
                } `}
              />
            </div>
            <div style={{ paddingTop: "10px" }} className="automatic-payment">
              <span
                style={{
                  paddingRight: "60px",
                }}
              >
                Last Name:
              </span>
              <input
                {...register("last_name", { required: true })}
                type="text"
                className={`form-control-automatic-payment ${
                  errors.last_name && "form-control-error"
                } `}
              />
            </div>
            <div style={{ paddingTop: "10px" }} className="automatic-payment">
              <span
                style={{
                  paddingRight: "44px",
                }}
              >
                Card Number:
              </span>
              <input
                {...register("number", { required: true })}
                type="password"
                placeholder=""
                className={`form-control-automatic-payment ${
                  errors.number && "form-control-error"
                } `}
              />
            </div>
            <div style={{ paddingTop: "10px" }} className="automatic-payment">
              <span
                style={{
                  paddingRight: "28px",
                }}
              >
                Expiration Month:
              </span>
              <select
                {...register("exp_month", { required: true })}
                className={`arrow-select ${
                  errors.exp_month && "form-control-error"
                } `}
              >
                <option value="1"> 1 </option>
                <option value="2"> 2 </option>
                <option value="3"> 3 </option>
                <option value="4"> 4 </option>
                <option value="5"> 5 </option>
                <option value="6"> 6 </option>
                <option value="7"> 7 </option>
                <option value="8"> 8 </option>
                <option value="9"> 9 </option>
                <option value="10"> 10 </option>
                <option value="11"> 11 </option>
                <option value="12"> 12 </option>
              </select>
            </div>
            <div style={{ paddingTop: "10px" }} className="automatic-payment">
              <span
                style={{
                  paddingRight: "40px",
                }}
              >
                Expiration Year:
              </span>
              <select
                className={`arrow-select ${
                  errors.exp_year && "form-control-error"
                } `}
                {...register("exp_year", { required: true })}
              >
                <option value="2022"> 2022 </option>
                <option value="2023"> 2023 </option>
                <option value="2024"> 2024 </option>
                <option value="2025"> 2025 </option>
                <option value="2026"> 2026 </option>
                <option value="2027"> 2027 </option>
                <option value="2028"> 2028 </option>
                <option value="2029"> 2029 </option>
                <option value="2030"> 2030 </option>
                <option value="2031"> 2031 </option>
                <option value="2032"> 2032 </option>
              </select>
            </div>
            <div style={{ paddingTop: "10px" }} className="automatic-payment">
              <span
                style={{
                  paddingRight: "66px",
                }}
              >
                Card Type:
              </span>
              <select
                className={`arrow-select ${
                  errors.type && "form-control-error"
                } `}
                {...register("type", { required: true })}
              >
                <option value="amex"> Amex </option>
                <option value="visa"> Visa </option>
                <option value="mastercard"> Mastercard </option>
                <option value="discover"> Discover </option>
              </select>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Button
                disabled={loading}
                onClick={() => {
                  reset();
                  setAddCC(false);
                }}
                variant="danger light"
              >
                Back
              </Button>
              <button
                disabled={loading}
                className="btn btn-primary mx-3"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          <>
            <p>
              Your invoice will be generated on the{" "}
              {cardData.billing_cycle_month_date} of every month
            </p>

            <div style={{ paddingTop: "10px" }} className="automatic-payment">
              <strong
                style={{
                  paddingRight: "10px",
                }}
              >
                Automatic Payments Are:
              </strong>
              <Switch onChange={handleChangeCheck} checked={checked} />
            </div>

            <div style={{ paddingTop: "20px" }} className="automatic-payment">
              <label>Current Card Details:</label>
            </div>

            {cardData?.credit_card_info?.type ? (
              <>
                <div className="credit-card">
                  <span>
                    <strong>***</strong>
                    {cardData.credit_card_info.last_4}
                  </span>
                  <span>
                    {cardData.credit_card_info.exp_month}/
                    {cardData.credit_card_info.exp_year}
                  </span>
                  {cardData?.credit_card_info?.type === "amex" && (
                    <div>
                      <img style={{ width: "40px" }} src={amxLogo} alt="" />
                    </div>
                  )}
                  {cardData?.credit_card_info?.type === "visa" && (
                    <div>
                      <img style={{ width: "40px" }} src={visaLogo} alt="" />
                    </div>
                  )}
                  {cardData?.credit_card_info?.type === "mastercard" && (
                    <div>
                      <img style={{ width: "40px" }} src={masterLogo} alt="" />
                    </div>
                  )}
                  {cardData?.credit_card_info?.type === "discover" && (
                    <div>
                      <img
                        style={{ width: "40px" }}
                        src={discoverLogo}
                        alt=""
                      />
                    </div>
                  )}
                  <Button onClick={() => setAddCC(true)} variant="primary">
                    Update
                  </Button>
                  <Button onClick={handleDeleteCreditCard} variant="primary">
                    Remove
                  </Button>
                </div>
              </>
            ) : (
              <div style={{ paddingTop: "10px" }} className="automatic-payment">
                <span
                  style={{
                    paddingRight: "10px",
                  }}
                >
                  No card saved
                </span>
                <Button onClick={() => setAddCC(true)} variant="primary">
                  Add
                </Button>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setModal(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(AddCardModal));
