import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Spinner,
  Pagination,
} from "react-bootstrap";
import { Button } from "reactstrap";
import ButtonStyled from "../../utils/ButtonStyled";
import ButtonHover from "../ButtonHover/ButtonHover";
import Loading from "../../jsx/components/Loading/Loading";
import Switch from "react-switch";
import DropDownMultiSelect from "./DropDownMultiSelect";
import PaginationITP from "../Pagination";
import TuneIcon from "@mui/icons-material/Tune";
import { Link } from "react-router-dom";

export default function CustomTable({
  removeSearchBar,
  data,
  text,
  size,
  bordered,
  pagination: paginationData,
  responsive,
  loading,
  listKeys,
  defaultValue,
  noMargin,
  noCard,
  noClass,
  switchComponent,
  handleChange,
  loadingDownloadCall,
  dataDownload,
  showMorefilters,
  clickRow,
}) {
  /* Example of Data  (content is array of endpoint)

  OBLIGATORIO:
   *title  (define los nombres de las columnas y a que valores entra)
   *content (lista de valores)

  OPCIONALES:

   *actions (para opciones de eliminar o editar)
   *search (funcion de busqueda en base a los campos del title)
   *addButton (para aquellas tablas que requieren un boton de agregas mas )


  data: {
          title: [
            {title: "Presence Id", key: "presence_id"},
            {title: "First Name", key: "first_name"},
            {title: "Last Name", key: "last_name"},
          ],
          content: [...result.result],
          actions: {
            title: "Devices",
            content: [
              {
                icon: <DescriptionIcon />,  //Svg icon
                handleClick: handleOpenDevicesModal,  //Function
              },
            ],
          },
          search:{
          label:"Label del input Search"
          },
          addButton: {
           label: "Add Menu",
                        handleClick: () => { setModalAdd(true) }
          }


        }


   Pagination

   pagination por default = 10
   responsive por default = true
   size por default = "md"


   loading  true para activarlo, false para desactivarlo0

    <CustomTable loading={false} responsive={true} pagination={3} bordered={true} size="md" data={data} />


 */
  const cantPagination = paginationData || 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [search, setSearch] = useState("");
  const [dataFilter, setDataFilter] = useState([]);
  const [selector, setSelector] = useState(false);
  const [paginationITP, setPaginationITP] = useState(cantPagination);

  useEffect(() => {
    if (paginationITP != pagination)
      setPagination(paginationITP * cantPagination);
  }, [paginationITP, pagination]);

  useEffect(() => {
    if (data) setDataFilter(data.content);
  }, [data]);

  useEffect(() => {
    let data = {
      target: {
        value: defaultValue,
      },
    };
    if (!selector && defaultValue) onChangeSearch(data);
  }, [search]);

  const onChangeSearch = (e) => {
    setSelector(true);
    setSearch(e.target.value);

    let dataFilterCopy = [];
    if (listKeys) {
      listKeys.map((element) => {
        data.content.filter((elementTwo) => {
          if (
            typeof elementTwo[element] === "string" &&
            elementTwo[element]
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) &&
            !dataFilterCopy.find(
              (elementThree) => elementThree.id === elementTwo.id
            )
          ) {
            dataFilterCopy.push(elementTwo);
          }
        });
      });
    } else {
      data.title.map((element) => {
        data.content.filter((elementTwo) => {
          if (
            typeof elementTwo[element.key] === "string" &&
            elementTwo[element.key]
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) &&
            !dataFilterCopy.find(
              (elementThree) => elementThree.id === elementTwo.id
            )
          ) {
            dataFilterCopy.push(elementTwo);
          }
        });
      });
    }

    if (e.target.value === "") return setDataFilter(data.content);

    setDataFilter(dataFilterCopy);
  };

  const handleDeleteSearch = () => {
    setSearch("");
    setDataFilter(data.content);
  };

  const onClickNext = () => {
    if (pagination < data.content.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>No</p>
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>Yes</p>
      </div>
    );
  };

  const validViewColumn = (arr) => {
    return arr.filter(
      (el) =>
        el.viewColumn === undefined ||
        el.viewColumn == null ||
        el.viewColumn === true
    );
  };

  if (data) {
    return (
      <div>
        <style type="text/css">
          {`
            .table-respon {
              overflow-x: visible !important;
            }
          `}
        </style>
        {/* <Card body className="m-0"> */}
        {/* Tools Options */}
        {(data.search || data.addButton) && (
          <>
            <div
              style={{
                minHeight: "50px",
                background: "white",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              <div className="d-flex">
                {data.showMorefilters && (
                  <Button color="link" onClick={() => data.showMorefilters()}>
                    <TuneIcon
                      style={{
                        marginLeft: "-10px",
                        marginRight: "5px",
                      }}
                    />
                    More Filters
                  </Button>
                )}
                {/* {data.search && !removeSearchBar && data.search.positionUp && (
                  <div className="col-6 col-lg-6">
                    <label
                      htmlFor="search"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "10px",
                        left: "20px",
                      }}
                    >
                      <i className="fas fa-magnifying-glass"></i>
                    </label>
                    <input
                      className="form-control"
                      id="search"
                      name="search"
                      onChange={onChangeSearch}
                      placeholder={data.search.label}
                      defaultValue={defaultValue}
                      type="text"
                      style={{
                        paddingLeft: "40px",
                        borderRadius: "3rem",
                        height: "45px",
                      }}
                    />

                    <ButtonHover
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "45px",
                        position: "absolute",
                        right: "20px",
                        bottom: "0",
                      }}
                      onClick={handleDeleteSearch}
                    >
                      <i className="fas fa-xmark"></i>
                    </ButtonHover>
                  </div>
                )} */}
              </div>

              {/* <div><Button color="link">More Filters</Button></div> */}
              <div className="d-flex">
                {data.viewMultiSelect === true && (
                  <div style={{ marginRight: "10px" }}>
                    <DropDownMultiSelect
                      options={data.title}
                      checkFunction={(e) => data.check(e, data)}
                    />
                  </div>
                )}

                {data.addButton && (
                  <div
                    className={`${
                      data.search && !removeSearchBar ? "6" : "12"
                    }`}
                  >
                    <ButtonStyled
                      onClickFunction={data.addButton.handleClick}
                      style={{ height: "50px !important", borderRadius: "1.25rem" }}
                      icon={
                        <i
                          style={{ marginRight: "4px" }}
                          className="fas fa-plus"
                        ></i>
                      }
                      text={data.addButton.label}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              {data.search && !removeSearchBar && !data.search.positionUp && (
                <div className="col-6 col-lg-6">
                  <label
                    htmlFor="search"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "10px",
                      left: "20px",
                    }}
                  >
                    <i className="fas fa-magnifying-glass"></i>
                  </label>
                  <input
                    className="form-control"
                    id="search"
                    name="search"
                    onChange={onChangeSearch}
                    placeholder={data.search.label}
                    defaultValue={defaultValue}
                    type="text"
                    style={{
                      paddingLeft: "40px",
                      borderRadius: "3rem",
                      height: "45px",
                    }}
                  />

                  <ButtonHover
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "45px",
                      position: "absolute",
                      right: "20px",
                      bottom: "0",
                    }}
                    onClick={handleDeleteSearch}
                  >
                    <i className="fas fa-xmark"></i>
                  </ButtonHover>
                </div>
              )}
            </div>
          </>
        )}

        {/* Table of data */}
        {dataFilter.length === 0 ? (
          loading ? (
            <Loading />
          ) : (
            <Card body>
              <h3 style={{ textAlign: "center", marginTop: "10px" }}>
                You currently have no {text ? text : "records"}
              </h3>
            </Card>
          )
        ) : (
          <>
            {noCard ? (
              <Row>
                <Col
                  style={{ padding: "1.875rem" }}
                  className={`${
                    data.title.length === 1
                      ? ""
                      : "custom-card-table-responsive"
                  }`}
                  lg={12}
                >
                  <Table
                    hover
                    style={{ marginTop: `${noMargin ? "" : "24px"}` }}
                  >
                    <thead>
                      <tr>
                        <th className="width80">#</th>
                        {validViewColumn(data.title).map((element, i) => (
                          <th key={i}>{element.title}</th>
                        ))}
                        {data.switch && <th>{data.switch.title}</th>}
                        {data.iconActions && <th>{data.iconActions.title}</th>}
                        {data.actions && <th>{data.actions.title}</th>}
                      </tr>
                    </thead>
                    <tbody
                    // className={`${
                    //   dataFilter.length === 1 && !noClass ? "h-res" : ""
                    // }`}
                    >
                      {dataFilter.map((element, i) => {
                        if (
                          (i < pagination &&
                            i >= pagination - cantPagination) ||
                          !pagination
                        ) {
                          return (
                            <tr
                              onClick={() => {
                                if (clickRow) clickRow(element);
                              }}
                              key={i}
                              style={{ cursor: clickRow ? "pointer" : "" }}
                            >
                              <td style={{ padding: "0.5rem 0.5rem" }}>
                                {i + 1}
                              </td>

                              {validViewColumn(data.title).map(
                                (elementTwo, index) => (
                                  <td
                                    // className="text-left"
                                    style={{ padding: "0.5rem 0.5rem" }}
                                    key={index}
                                  >
                                    {elementTwo.type === "dropdown" ? (
                                      <select
                                        onChange={(e) => {
                                          elementTwo.onHandleClick(
                                            data,
                                            element["row"],
                                            e.target.value
                                          );
                                        }}
                                        className="arrow-select"
                                        value={element[elementTwo.key]}
                                      >
                                        {console.log("---------->", elementTwo)}

                                        <option value=""> Select One </option>
                                        {elementTwo.options.map(
                                          (option, index2) => (
                                            <option
                                              key={index2}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    ) : elementTwo.link ? (
                                      <Link
                                        to={`${elementTwo.path}?${
                                          elementTwo.param
                                            ? elementTwo.param
                                            : elementTwo.secondKey
                                            ? `${
                                                element[elementTwo.key][
                                                  elementTwo.secondKey
                                                ]
                                              }`
                                            : `${element[elementTwo.key]}`
                                        }`}
                                      >
                                        {elementTwo.secondKey
                                          ? `${
                                              element[elementTwo.key][
                                                elementTwo.secondKey
                                              ]
                                            }`
                                          : `${element[elementTwo.key]}`}
                                      </Link>
                                    ) : (
                                      <div
                                        style={{
                                          backgroundColor: elementTwo.status
                                            ? element[elementTwo.key][
                                                elementTwo.colorKey
                                              ]
                                            : "",
                                          display: elementTwo.display
                                            ? elementTwo.display
                                            : "",
                                          justifyContent:
                                            elementTwo.justifyContent
                                              ? elementTwo.justifyContent
                                              : "",
                                          color: elementTwo.color
                                            ? elementTwo.color
                                            : "",
                                        }}
                                      >
                                        {elementTwo.secondKey
                                          ? `${
                                              element[elementTwo.key][
                                                elementTwo.secondKey
                                              ]
                                            }`
                                          : `${element[elementTwo.key]}`}
                                      </div>
                                    )}
                                  </td>
                                )
                              )}
                              {data.switch && (
                                <td style={{ padding: "0.5rem 0.5rem" }}>
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="mr-1"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() => handleChange(element)}
                                    height={24}
                                    width={48}
                                    checked={switchComponent}
                                  />
                                </td>
                              )}

                              {data.switchComponent && (
                                <td>
                                  {data.switchComponent.content.map(
                                    (elementTwo) => (
                                      <>
                                        {elementTwo.type === "component" &&
                                          elementTwo.component(element)}
                                        {elementTwo.type !== "component" &&
                                          elementTwo.switch && (
                                            <Switch
                                              uncheckedIcon={<Offsymbol />}
                                              className="mr-1"
                                              checkedIcon={<OnSymbol />}
                                              onColor="#626ed4"
                                              onChange={() => {
                                                elementTwo.handleClick(element);
                                              }}
                                              height={24}
                                              width={48}
                                              checked={
                                                element[
                                                  `${elementTwo.keySwitch}`
                                                ]
                                              }
                                            />
                                          )}
                                      </>
                                    )
                                  )}
                                </td>
                              )}

                              {data.iconActions && (
                                <td>
                                  {data.iconActions.content.map(
                                    (elementTwo, index) => (
                                      <>
                                        {loadingDownloadCall &&
                                        dataDownload.pk === element.pk ? (
                                          <Spinner
                                            style={{
                                              marginLeft: "40px",
                                            }}
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <ButtonHover
                                            key={index}
                                            style={{
                                              margin:
                                                data.iconActions.content
                                                  .length === 1
                                                  ? "0 auto"
                                                  : "0",
                                            }}
                                            onClick={() => {
                                              elementTwo.handleClick(element);
                                            }}
                                          >
                                            {elementTwo.icon}
                                          </ButtonHover>
                                        )}
                                      </>
                                    )
                                  )}
                                </td>
                              )}

                              {data.actions && (
                                <td style={{ padding: "0.5rem 0.5rem" }}>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant=""
                                      className="btn btn-primary tp-btn-light sharp i-false"
                                    >
                                      <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      style={{
                                        transform: "translate(-44px, -35px)",
                                      }}
                                    >
                                      {data.actions.content.map(
                                        (elementTwo, index) => (
                                          <Dropdown.Item
                                            key={index}
                                            onClick={() =>
                                              elementTwo.handleClick(element)
                                            }
                                          >
                                            {elementTwo.icon} {elementTwo.name}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              )}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={12}>
                  <Card>
                    <Card.Body
                      className={`${
                        data.title.length === 1
                          ? ""
                          : "custom-card-table-responsive"
                      }`}
                    >
                      <Table
                        hover
                        style={{ marginTop: `${noMargin ? "" : "24px"}` }}
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            {validViewColumn(data.title).map((element, i) => (
                              <th key={i}>{element.title}</th>
                            ))}
                            {data.switch && <th>{data.switch.title}</th>}
                            {data.switchComponent && (
                              <th>{data.switchComponent.title}</th>
                            )}
                            {data.iconActions && (
                              <th>{data.iconActions.title}</th>
                            )}
                            {data.actions && <th>{data.actions.title}</th>}
                          </tr>
                        </thead>
                        <tbody
                          className={`${
                            dataFilter.length === 1 && !noClass ? "" : ""
                          }`}
                        >
                          {dataFilter.map((element, i) => {
                            if (
                              (i < pagination &&
                                i >= pagination - cantPagination) ||
                              !pagination
                            ) {
                              return (
                                <tr
                                  onClick={() => {
                                    if (clickRow) clickRow(element);
                                  }}
                                  key={i}
                                  style={{ cursor: clickRow ? "pointer" : "" }}
                                >
                                  <td style={{ padding: "0.5rem 0.5rem" }}>
                                    {i + 1}
                                  </td>

                                  {validViewColumn(data.title).map(
                                    (elementTwo, index) => (
                                      <td
                                        // className="text-left"
                                        style={{ padding: "0.5rem 0.5rem" }}
                                        key={index}
                                      >
                                        {elementTwo.type === "dropdown" ? (
                                          <select
                                            onChange={(e) => {
                                              elementTwo.onHandleClick(
                                                data,
                                                element["row"],
                                                e.target.value
                                              );
                                            }}
                                            className="arrow-select"
                                            value={element[elementTwo.key]}
                                          >
                                            {console.log(
                                              "---------->",
                                              elementTwo
                                            )}

                                            <option value="">
                                              {" "}
                                              Select One{" "}
                                            </option>
                                            {elementTwo.options.map(
                                              (option, index2) => (
                                                <option
                                                  key={index2}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        ) : elementTwo.link ? (
                                          <Link
                                            to={`${elementTwo.path}?${
                                              elementTwo.param
                                                ? elementTwo.param
                                                : elementTwo.secondKey
                                                ? `${
                                                    element[elementTwo.key][
                                                      elementTwo.secondKey
                                                    ]
                                                  }`
                                                : `${element[elementTwo.key]}`
                                            }`}
                                          >
                                            {elementTwo.secondKey
                                              ? `${
                                                  element[elementTwo.key][
                                                    elementTwo.secondKey
                                                  ]
                                                }`
                                              : `${element[elementTwo.key]}`}
                                          </Link>
                                        ) : (
                                          <div
                                            style={{
                                              backgroundColor: elementTwo.status
                                                ? element[elementTwo.key][
                                                    elementTwo.colorKey
                                                  ]
                                                : "",
                                              display: elementTwo.display
                                                ? elementTwo.display
                                                : "",
                                              justifyContent:
                                                elementTwo.justifyContent
                                                  ? elementTwo.justifyContent
                                                  : "",
                                              color: elementTwo.color
                                                ? elementTwo.color
                                                : "",
                                            }}
                                          >
                                            {elementTwo.secondKey
                                              ? `${
                                                  element[elementTwo.key][
                                                    elementTwo.secondKey
                                                  ]
                                                }`
                                              : `${element[elementTwo.key]}`}
                                          </div>
                                        )}
                                      </td>
                                    )
                                  )}
                                  {data.switch && (
                                    <td style={{ padding: "0.5rem 0.5rem" }}>
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        className="mr-1"
                                        checkedIcon={<OnSymbol />}
                                        onColor="#626ed4"
                                        onChange={() => handleChange(element)}
                                        height={24}
                                        width={48}
                                        checked={switchComponent}
                                      />
                                    </td>
                                  )}

                                  {data.switchComponent && (
                                    <td>
                                      {data.switchComponent.content.map(
                                        (elementTwo) => (
                                          <>
                                            {elementTwo.type === "component" &&
                                              elementTwo.component(element)}
                                            {elementTwo.type !== "component" &&
                                              elementTwo.switch && (
                                                <Switch
                                                  uncheckedIcon={<Offsymbol />}
                                                  className="mr-1"
                                                  checkedIcon={<OnSymbol />}
                                                  onColor="#626ed4"
                                                  onChange={() => {
                                                    elementTwo.handleClick(
                                                      element
                                                    );
                                                  }}
                                                  height={24}
                                                  width={48}
                                                  checked={
                                                    element[
                                                      `${elementTwo.keySwitch}`
                                                    ]
                                                  }
                                                />
                                              )}
                                          </>
                                        )
                                      )}
                                    </td>
                                  )}

                                  {data.iconActions && (
                                    <td>
                                      {data.iconActions.content.map(
                                        (elementTwo, index) => (
                                          <>
                                            {loadingDownloadCall &&
                                            dataDownload.pk === element.pk ? (
                                              <Spinner
                                                style={{
                                                  marginLeft: "40px",
                                                }}
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <ButtonHover
                                                key={index}
                                                style={{
                                                  margin:
                                                    data.iconActions.content
                                                      .length === 1
                                                      ? "0 auto"
                                                      : "0",
                                                }}
                                                onClick={() => {
                                                  elementTwo.handleClick(
                                                    element
                                                  );
                                                }}
                                              >
                                                {elementTwo.icon}
                                              </ButtonHover>
                                            )}
                                          </>
                                        )
                                      )}
                                    </td>
                                  )}

                                  {data.actions && (
                                    <td style={{ padding: "0.5rem 0.5rem" }}>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant=""
                                          className="btn btn-primary tp-btn-light sharp i-false"
                                        >
                                          <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              strokeWidth="1"
                                              fill="none"
                                              fillRule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <circle
                                                fill="#000000"
                                                cx="5"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="12"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="19"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                            </g>
                                          </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          style={{
                                            transform:
                                              "translate(-44px, -35px)",
                                          }}
                                        >
                                          {data.actions.content.map(
                                            (elementTwo, index) => (
                                              <Dropdown.Item
                                                key={index}
                                                onClick={() =>
                                                  elementTwo.handleClick(
                                                    element
                                                  )
                                                }
                                              >
                                                {elementTwo.icon}{" "}
                                                {elementTwo.name}
                                              </Dropdown.Item>
                                            )
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
        {dataFilter && dataFilter.length > cantPagination && (
          <PaginationITP
            pages={
              (dataFilter.length / cantPagination) % 1 === 0
                ? Math.floor(dataFilter.length / cantPagination)
                : Math.floor(dataFilter.length / cantPagination + 1)
            }
            active={pagination / cantPagination}
            setActive={setPaginationITP}
          ></PaginationITP>
        )}
      </div>
    );
  }
  return <Loading />;
}
