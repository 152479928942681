import axios from "axios";
import json from "../config.json";
export default function petitionDelete(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  var url;

  if (token) {
    switch (key) {
      case "deletecc":
        url = urlBase + "/billing/account/" + value + "/payments/creditcard";
        break;

      case "deleteUser":
        url = urlBase + "/itpvoice/" + value.api_id + "/users/" + value.user_id;
        break;
      case "deleteRingGroup":
        url =
          urlBase + `/itpvoice/${value.id}/ring-groups/${value.ring_group_id}`;
        break;
      case "deleteQueues":
        url = urlBase + `/itpvoice/${value.id}/queues/${value.queues_id}`;
        break;
      case "deleteVoicemail":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/voicemail-messages/${value.media_id}/audio`;

        break;
      case "deleteContactList":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/contacts/${value.contact_id}`;
        break;
      case "deleteNumber":
        url = urlBase + `/itpvoice/${value.id}/numbers/${value.phone}`;
        break;
      case "deleteNumberLegacy":
        url = urlBase + `/itpvoice-legacy/${value.id}/numbers/${value.phone}`;
        break;

      case "deleteApp":
        url =
          urlBase +
          `/itpvoice/${value.id}/app-store/installed-apps/${value.pk}`;
        break;
      case "apiKeys":
        url = urlBase + `/itpvoice/${value.id}/apikeys/${value.pk}`;
        break;

      case "timeGroup":
        url = urlBase + `/itpvoice/${value.id}/time-groups/${value.time_id}`;
        break;
      case "timeDate":
        url = urlBase + `/itpvoice/${value.id}/time-dates/${value.time_id}`;
        break;
      case "callFlow":
        url = urlBase + `/itpvoice/${value.id}/callflows/${value.callflow_id}`;
        break;
      case "deleteMenu":
        url = urlBase + `/itpvoice/${value.id}/menus/${value.menuId}`;
        break;
      case "deleteCallflow":
        url = urlBase + `/itpvoice/${value.id}/devices/${value.callFlowId}`;
        break;

      case "deleteMessageSms":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/message-content/${value.smsId}`;

        break;
      case "deleteThreadSms":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/chat/sms/${value.number}/${value.smsId}`;
        break;
      case "deleteGroup":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/contact-groups/${value.groupPk}`;

        break;

      case "contacts":
        url = urlBase + `/crm/contacts/${value.id}`;
        break;
      case "licencesDelete":
        url =
          urlBase +
          `/crm/contacts/${value.contact_id}/licenses/${value.license_id}`;
        break;
      default:
        return "error";
    }
    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };
    return axios.delete(url, config);
  } else {
    window.location.href = "./profile";
  }
}
