import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import petitionPost from "../../../../../services/petitionPost";
import {
  sendToastError,
  sendToastSuccess,
} from "../../../../../utils/toastAlert";
function AddTickets({setStep, profile}) {
  let {id, user_contact} = profile?.auth?.auth;

  console.log(profile);

  const [loading, setLoading] = useState(false);

  const formConfig = {
    defaultValues: {
      subject: "",
      description: "",
      status: "Open",
      priority: "Normal",
      contact_id: id,
      account_id: user_contact.account_id,
    },
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm(formConfig);

  const handleSubmitSave = (data) => {
    setLoading(true);

    petitionPost("addTicket", {data})
      .then((result) => {
        sendToastSuccess();
        setStep(1);
      })
      .catch((err) => sendToastError())
      .finally(() => setLoading(false));
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="email-right-box ms-0 ms-sm-4 ms-sm-0">
          <div className="compose-content">
            <form onSubmit={handleSubmit(handleSubmitSave)}>
              <div className="form-group mb-3">
                <input
                  {...register("subject", {required: true})}
                  type="text"
                  className={`form-control bg-transparent ${
                    errors.subject && "form-control-error"
                  } `}
                  placeholder=" Subject:"
                />
              </div>
              <div className="form-group mb-3">
                <textarea
                  {...register("description", {required: true})}
                  type="text"
                  className={`textarea_editor form-control bg-transparent ${
                    errors.description && "form-control-error"
                  } `}
                  rows="8"
                  placeholder="Enter text ..."
                ></textarea>
              </div>
            </form>
            {/*             <h5 className="mb-4">
              <i className="fa fa-paperclip"></i> Attatchment
            </h5>
            <DropFile /> */}
          </div>
          <div className="text-left mt-4 mb-5">
            <button
              onClick={handleSubmit(handleSubmitSave)}
              className="btn btn-primary btn-sl-sm me-2"
              type="button"
              disabled={loading}
            >
              <span className="me-2">
                <i className="fa fa-paper-plane"></i>
              </span>
              Send
            </button>
            <button
              onClick={() => setStep(1)}
              className="btn btn-danger light btn-sl-sm"
              type="button"
            >
              <span className="me-2">
                <i className="fa fa-times" aria-hidden="true"></i>
              </span>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(AddTickets));
