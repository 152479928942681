import axios from "axios";

import json from "../config.json";
export default function petitionPost(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).token;
  var url;
  var data;
  if (token) {
    switch (key) {
      case "approve":
        url = urlBase + "/crm/approve";
        data = value;
        break;
      case "profile":
        url = urlBase + "/crm/myprofile";
        data = value;
        break;
      case "payments_creditcard":
        url = urlBase + "/billing/account/" + value[1] + "/payments/creditcard";
        data = value[0];
        break;
      case "payments_creditcard-onfile":
        url =
          urlBase +
          "/billing/account/" +
          value[1] +
          "/payments/creditcard-onfile";
        data = value[0];
        break;
      case "orders":
        url = urlBase + `/itpvoice/v2/${value.id}/numbers`;
        data = value.data;
        break;
      case "usersValidate":
        url = urlBase + `/itpvoice/${value.id}/users/validate`;
        data = value.data;
        break;
      case "users":
        url = urlBase + `/itpvoice/v2/${value.id}/users`;
        data = value.data;
        break;
      case "sendSms":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/my-extension/chat/sms/${value.number}`;
        data = value.data;
        break;
      case "addRingGroup":
        url = urlBase + `/itpvoice/v2/${value.id}/ring-groups`;
        data = value.data;
        break;
      case "addQueues":
        url = urlBase + `/itpvoice/v2/${value.id}/queues`;
        data = value.data;
        break;
      case "musicOnHoldMedia":
        url = urlBase + `/itpvoice/v2/${value.id}/media`;
        data = value.data;
        break;
      case "addContactList":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/contacts`;
        data = value.data;
        break;
      case "contactsUser":
        url = urlBase + "/crm/contacts";
        data = value.data;
        break;
      case "addNumberPBX":
        url = urlBase + `/itpvoice/v2/${value.id}/legacy-numbers`;
        data = value.data;
        break;
      case "addNumberLegacy":
        url = urlBase + `/itpvoice-legacy/${value.id}/numbers`;
        data = value.data;
        break;
      case "installApp":
        url = urlBase + `/itpvoice/v2/${value.id}/app-store/installed-apps`;
        data = value.data;
        break;
      case "transferNumbers":
        url = urlBase + `/itpvoice/v2/${value.id}/numbers/lnp`;
        data = value.data;
        break;
      case "transferNumbersLegacy":
        url = urlBase + `/itpvoice-legacy/${value.id}/numbers/lnp`;
        data = value.data;
        break;
      case "verifyTransferNumbers":
        url = urlBase + `/itpvoice/v2/${value.id}/lnp-verify`;
        data = value.data;
        break;
      case "verifyTransferNumbersLegacy":
        url = urlBase + `/itpvoice-legacy/${value.id}/lnp-verify`;
        data = value.data;
        break;
      case "authorizeTrensfer":
        url = urlBase + `/itpvoice/v2/${value.id}/numbers/lnp-pending-auth`;
        data = value.data;
        break;
      case "apiKeys":
        url = urlBase + `/itpvoice/v2/${value.id}/apikeys`;
        data = value.data;
        break;
      case "timeGroup":
        url = urlBase + `/itpvoice/v2/${value.id}/time-groups`;

        data = value.data;
        break;
      case "timeDate":
        url = urlBase + `/itpvoice/v2/${value.id}/time-dates`;

        data = value.data;
        break;
      case "callflow":
        url = urlBase + `/itpvoice/v2/${value.id}/callflows`;

        data = value.data;
        break;
      case "reports":
        url = urlBase + `/itpvoice/v2/${value.id}/call-reports${value.query}`;
        data = value.data;
        break;
      case "createMenu":
        url = urlBase + `/itpvoice/v2/${value.id}/menus`;
        data = value.data;
        break;
      case "createDevices":
        url = urlBase + `/itpvoice/v2/${value.id}/devices`;
        data = value.data;
        break;
      case "updateNote":
        url = urlBase + `/itpvoice/v2/${value.id}/callnotes`;
        data = value.data;
        break;
      case "addGroupContact":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/contact-groups`;
        data = value.data;
        break;
      case "numberCallReports":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/numbers-call-reports${value.query}`;
        data = value.data;
        break;
      case "timeRules":
        url = urlBase + `/itpvoice/v2/${value.id}/time-rules`;
        data = value.data;
        break;
      case "createQueueMember":
        url =
          urlBase + `/itpvoice/v2/${value.id}/queues/${value.queue_id}/members`;
        data = value.data;
        break;
      case "importContactCsv":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/export-contacts`;
        data = value.data;
        break;
      case "createTtsMedia":
        url = urlBase + `/itpvoice/v2/${value.id}/tts-media`;
        data = value.data;
        break;
      case "callparking":
        url = urlBase + `/itpvoice/v2/${value.id}/callparking`;
        data = value.data;
        break;
      case "fax":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/fax`;
        data = value.data;
        break;
      case "addWebhooks":
        url = urlBase + `/itpvoice/v2/${value.id}/webhooks`;
        data = value.data;
        break;
      case "addTicket":
        url = urlBase + `/crm/tickets`;
        data = value.data;
        break;
      case "addLicenses":
        url = urlBase + `/crm/contacts/${value.contact_id}/licenses`;
        data = value.data;
        break;
      default:
        return "error";
    }
    var config = {
      timeout: 120000,
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };
    return axios.post(url, data, config);
  } else {
    window.location.href = "./profile";
  }
}
