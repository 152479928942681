import React, { useState, useEffect } from "react";

import petitionGet from "../../../services/petitionGet";
import CustomTable from "../../../utils/CustomTable/CustomTable";
import CallMadeIcon from "@material-ui/icons/CallMade";

const Services = () => {
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [apiId, setApiId] = useState(null);

  const json = require("../../../config.json");

  const linkPbx = json.prod ? json.pbx.prod : json.pbx.dev;
  const payLink = json.prod ? json.payLink.prod : json.payLink.dev;

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  useEffect(() => {
    petitionGet("services")
      .then(({ data: result }) => {
        result.result.map((element, index) => {
          if (element.prod_description === "Microsoft 365 Business Standard") {
            element.prod_description = "Office 365 License";
          } else if (
            element.prod_description === "ITP Voice Hosted PBX (1) User/Seat"
          ) {
            element.prod_description = "ITP Voice UCS";
            element.route = `${linkPbx}/login?token=${token}&&v2=${element.api_id}`;
            setApiId(element.api_id);
          } else if(element.prod_description === "paylink360 description"){
            element.prod_description = "Paylink 360";
            element.route = `${window.location.host === "localhost:3000"
            ? `http://localhost:3002/login?token=${token}&&v2=${element.api_id}`
            : `${payLink}/login?token=${token}&&v2=${element.api_id}`
            }`;
          }
        });

        setDataTable({
          title: [
            { title: "Service Name", key: "prod_description" },
            { title: "Quantity", key: "quantity" },
            { title: "Monthly Price", key: "price" },
            { title: "Status", key: "status" },
          ],
          content: result.result,
          actions: {
            title: "App",
            content: [
              {
                icon: <CallMadeIcon />, //Svg icon
                name: "App",
                handleClick: (e) => {
                  if (e.item_type === "office365") {
                    console.log("office365");
                  } else if (e.item_type === "itp_voice") {
                    window.location.href = e.route;
                  } else if(e.item_type === "PayLink360"){
                    window.location.href = e.route;
                  }
                },
              },
            ],
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <CustomTable data={dataTable} noClass={true} removeSearchBar={true} />;
};

export default Services;
