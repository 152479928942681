import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import petitionGet from "../../../services/petitionGet";
import { sendToastError, sendToastSuccess } from "../../../utils/toastAlert";
import petitionPost from "../../../services/petitionPost";

function AddCardModal({ setModal, modal, profile, reloadData }) {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    admin_group: "false",
    billing_group: "false",
    technical_group: "false",
    time_zone: "US/Alaska",
  });

  const [activeToggle, setActiveToggle] = useState("profile");

  let { account } = profile.auth.auth;

  const handleSubmitSave = () => {
    if (activeToggle === "profile") setActiveToggle("manageLicenses");
    else {
      if (form.firstname === "") {
        setErrorFirstName(true);
        return setActiveToggle("profile");
      }

      let dataForm = form;
      dataForm.admin_group = dataForm.admin_group === "true" ? true : false;
      dataForm.billing_group = dataForm.billing_group === "true" ? true : false;
      dataForm.technical_group =
        dataForm.technical_group === "true" ? true : false;

      setLoading(true);
      petitionPost("contactsUser", {
        data: { ...dataForm, account_id: account.pk },
      })
        .then((result) => {
          setModal(false);
          reloadData();
          sendToastSuccess();

          services.map((service) => {
            if (service.checked) {
              petitionPost("addLicenses", {
                contact_id: result.data.result.pk,
                data: { service_id: service.pk },
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          sendToastError();
        })
        .finally(() => setLoading(false));
    }
  };

  let petititions = async () => {
    let licenses;
    await petitionGet("contactLicenses", account.pk)
      .then(({ data: { result } }) => {
        licenses = result;
      })
      .catch((error) => {
        console.log(error);
      });

    petitionGet("services")
      .then(({ data: { result } }) => {
        let services = [];
        result.map((service) => {
          let findLicense = licenses.find(
            (license) => license.service_id === service.pk
          );

          if (findLicense) services.push({ ...service, available: false });
          else services.push({ ...service, available: true });
        });
        setServices(services);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    petititions();
  }, []);

  const onChangeChecked = (e) => {
    let tempServices = [
      ...services.map((service) =>
        service.pk === parseInt(e.target.name)
          ? { ...service, checked: e.target.checked }
          : service
      ),
    ];

    setServices(tempServices);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if (e.target.name === "firstname") setErrorFirstName(false);
  };

  return (
    <Modal className="fade" show={modal} centered size="lg">
      <Modal.Header>
        <Modal.Title>Add User</Modal.Title>
        <Button
          onClick={() => setModal(false)}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="profile-tab">
          <div className="custom-tab-1">
            <div className="tab-content">
              <div
                id="profile-settings"
                className={`tab-pane fade ${
                  activeToggle === "profile" ? "active show" : ""
                }`}
              >
                <div className="row" style={{ paddingTop: "0px" }}>
                  <div className="form-group mb-3 col-6 ">
                    <label htmlFor="firstname" className="form-label">
                      First name
                    </label>
                    <input
                      id="firstname"
                      name="firstname"
                      type="text"
                      placeholder=""
                      onChange={(e) => handleChange(e)}
                      className={`form-control ${
                        errorFirstName && "form-control-error"
                      } `}
                    />
                  </div>
                  <div className="form-group mb-3 col-6 ">
                    <label htmlFor="lastname" className="form-label">
                      Last name
                    </label>
                    <input
                      id="lastname"
                      name="lastname"
                      type="text"
                      placeholder=""
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mb-3 col-6 ">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      placeholder=""
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mb-3 col-6 ">
                    <label htmlFor="admin_group" className="form-label">
                      Admin Group
                    </label>
                    <select
                      id="admin_group"
                      name="admin_group"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={form.admin_group}
                    >
                      <option value="" disabled>
                        Select Option
                      </option>
                      <option value={true}> True </option>
                      <option value={false}> False </option>
                    </select>
                  </div>
                  <div className="form-group mb-3 col-6 ">
                    <label htmlFor="billing_group" className="form-label">
                      Billing Group
                    </label>
                    <select
                      id="billing_group"
                      name="billing_group"
                      className="form-control"
                      value={form.billing_group}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="" disabled>
                        Select Option
                      </option>
                      <option value={true}> True </option>
                      <option value={false}> False </option>
                    </select>
                  </div>
                  <div className="form-group mb-3 col-6 ">
                    <label htmlFor="technical_group" className="form-label">
                      Technical Group
                    </label>
                    <select
                      id="technical_group"
                      name="technical_group"
                      className="form-control"
                      value={form.technical_group}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="" disabled>
                        Select Option
                      </option>
                      <option value={true}> True </option>
                      <option value={false}> False </option>
                    </select>
                  </div>
                  <div className="form-group mb-3 col-6 ">
                    <label htmlFor="time_zone" className="form-label">
                      Timezone
                    </label>
                    <select
                      id="time_zone"
                      name="time_zone"
                      className="form-control"
                      value={form.time_zone}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="" disabled>
                        Select Option
                      </option>
                      <option value="US/Alaska">US/Alaska GMT-9:00</option>

                      <option value="US/Arizona">US/Arizona GMT-7:00</option>
                      <option value="US/Central">US/Central GMT-6:00</option>
                      <option value="US/Eastern">US/Eastern GMT-5:00</option>
                      <option value="US/Hawaii">US/Hawaii GMT-10:00</option>
                      <option value="US/Indiana-Starke">
                        US/Indiana-Starke GMT-6:00
                      </option>
                      <option value="US/Michigan">US/Michigan GMT-5:00</option>
                      <option value="US/Mountain">US/Mountain GMT-7:00</option>
                      <option value="US/Pacific">US/Pacific GMT-8:00</option>
                      <option value="US/Samoa">US/Samoa GMT-11:00</option>

                      <option value="Asia/Hong_Kong">
                        Asia/Hong_Kong GMT+8:00
                      </option>
                      <option value="Europe/Berlin">
                        Europe/Berlin GMT+1:00
                      </option>
                      <option value="Europe/Madrid">
                        Europe/Madrid GMT+1:00
                      </option>
                      <option value="Europe/Rome">Europe/Rome GMT+1:00</option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                id="profile-settings"
                className={`tab-pane fade ${
                  activeToggle === "manageLicenses" ? "active show" : ""
                }`}
              >
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Service</th>
                      <th>license</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="customers">
                    {services.map((service, i) => {
                      if (
                        service.prod_description ===
                        "ITP Voice Hosted PBX (1) User/Seat"
                      ) {
                        return (
                          <tr key={i} className="btn-reveal-trigger">
                            <td>
                              <div className="media d-flex align-items-center">
                                <div className="media-body">
                                  <h5 className="mb-0 fs--1">
                                    VOICE360 / CRM360
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input  product_order_single"
                                name={service.pk}
                                checked={service.checked || false}
                                onChange={onChangeChecked}
                              />
                            </td>
                            <td></td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {activeToggle !== "profile" && (
          <Button
            onClick={() => setActiveToggle("profile")}
            className="btn btn-primary mx-3"
          >
            Back
          </Button>
        )}

        <Button
          onClick={handleSubmitSave}
          disabled={loading}
          className="btn btn-primary mx-3"
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {activeToggle === "profile" ? "Next" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(AddCardModal));
