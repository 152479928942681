import React, {Fragment, useContext, useState} from "react";
/// React router dom
import {Link} from "react-router-dom";
import {ThemeContext} from "../../../context/ThemeContext";
import logoBlack from "../../../images/logoBlack.png";
const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const {navigationHader, openMenuToggle, background} =
    useContext(ThemeContext);
  return (
    <div className="nav-header">
      <Link to="#" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <img
              src="https://itp360.com/img/core-img/logo.png"
              alt=""
              srcset=""
              style={{width: "70px"}}
            />
            <div className="brand-title">
              <h2 className="">ITP 360</h2>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <img src={logoBlack} alt="" srcSet="" style={{width: "70px"}} />
            <div className="brand-title">
              <h2 className="">ITP 360</h2>
            </div>
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
