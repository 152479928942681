import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

// image
import logoBlack from "../../images/logoBlack.png";
import { Spinner } from "react-bootstrap";

function Login(props) {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const Email = (e) => {
    setEmail(e.target.value);
    setErrorEmail(false);
  };

  const Password = (e) => {
    setPassword(e.target.value);
    setErrorPassword(false);
  };

  function onLogin(e) {
    e.preventDefault();
    if (email === "") {
      setErrorEmail(true);
    }
    if (password === "") {
      setErrorPassword(true);
    }

    if (email !== "" && password !== "") {
      // setLoading(true);
      dispatch(loadingToggleAction(true));
      dispatch(loginAction(email, password, props.history, setLoading));
      // .finally(() =>
      //   setLoading(false)
      // );
    }
  }

  // useEffect(() => {
  //   const params = new URL(document.location).searchParams;
  //   const token = params.get("token");

  //   if (token) {
  //     localStorage.setItem("userDetails", JSON.stringify({ token }));

  //     petitionGet("refresh")
  //       .then((result) => {
  //         console.log(result);
  //         localStorage.setItem(
  //           "userDetails",
  //           JSON.stringify({ ...result.data.result, token })
  //         );
  //         // window.location.href = "/profile";
  //       })
  //       .catch((err) => {
  //         window.location.href = `https://portal${
  //           json.prod ? "" : ".dev"
  //         }.itp360.com/login?redirect=https://app${
  //           json.prod ? "" : ".dev"
  //         }.voice360.app`;
  //         sendToastError();
  //       });
  //     /*  .finally(() => setLoading(false)); */
  //   } else {
  //     // window.location.href = `https://portal${
  //     //   json.prod ? "" : ".dev"
  //     // }.itp360.com/login?redirect=https://app${
  //     //   json.prod ? "" : ".dev"
  //     // }.voice360.app`;
  //   }
  // }, []);

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-4 pt-5">
            <img src={logoBlack} alt="" style={{ width: "200px" }} />
          </div>
          <h3 className="mb-2">Welcome back!</h3>
        </div>
        {/* <div
          className="aside-image"
          style={{backgroundImage: "url(" + loginbg + ")"}}
        ></div> */}
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form   form-validation">
                  {props.errorMessage && (
                    <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                      {props.errorMessage}
                    </div>
                  )}
                  {props.successMessage && (
                    <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                      {props.successMessage}
                    </div>
                  )}
                  <form onSubmit={(e) => onLogin(e)} className="form-validate">
                    <h3 className="text-center mb-4 text-black">
                      Sign in your account
                    </h3>
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="val-email">
                        <strong>Email</strong>
                      </label>
                      <div>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => Email(e)}
                          placeholder="Type Your Email Address"
                        />
                      </div>
                      {errorEmail && (
                        <div className="text-danger fs-12">
                          Email is Required
                        </div>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      {/* <label className="mb-1">
                        <strong>Password</strong>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        value={password}
                        placeholder="Type Your Password"
                        onChange={(e) => setPassword(e.target.value)}
                      /> */}

                      <label className="mb-1">
                        <strong>Password</strong>
                      </label>
                      <div className="input-group transparent-append mb-2">
                        <input
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control"
                          value={password}
                          onChange={(e) => Password(e)}
                          placeholder="Type Your Password"
                        />
                        <div
                          className="input-group-text show-pass"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {" "}
                          {showPassword === true ? (
                            <i className="fa fa-eye" />
                          ) : (
                            <i className="fa fa-eye-slash" />
                          )}
                        </div>
                      </div>

                      {errorPassword && (
                        <div className="text-danger fs-12">
                          Password is Required
                        </div>
                      )}
                    </div>
                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                      <div className="form-group mb-3">
                        <div className="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="basic_checkbox_1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="basic_checkbox_1"
                          >
                            Remember my preference
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-center form-group mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {loading && (
                          <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        Sign In
                      </button>
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      <Link to="/page-forgot-password" className="text-muted">
                        <i className="mdi mdi-lock mr-1"></i> Forgot your
                        password?
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
