import React, { useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Button } from "react-bootstrap";
import Orders from "./Orders";
import Statements from "./Statements";
import petitionGet from "../../../../services/petitionGet";
import { sendToastError } from "../../../../utils/toastAlert";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import AddCardModal from "./AddCardModal";
import MakePayment from "./makePayment";
const ProductOrder = (props) => {
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState("orders");
  const [dataAccount, setDataAccount] = useState("");

  let { account } = props.profile.auth.auth;

  useEffect(() => {
    petitionGet("account", account.billing_api_id)
      .then(({ data: { result } }) => setDataAccount(result))
      .catch((err) => sendToastError());
  }, [step]);

  return (
    <div className="h-80">
      <PageTitle activeMenu="Payments" motherMenu="Billing" />

      <AddCardModal setModal={setModal} modal={modal} />

      <div className="row">
        {step === "orders" && <Orders />}
        {step === "statements" && <Statements />}
        {step === "makePayment" && (
          <MakePayment setStep={setStep} balance={dataAccount.balance} />
        )}

        <div className="col-xl-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-statistics">
                    <div className="text-center">
                      <div className="row">
                        <div className="col">
                          <h3 className="m-b-0">
                            ${Number(dataAccount.balance).toLocaleString()}
                          </h3>
                          <span>Current Balance Due</span>
                        </div>
                      </div>
                      <div className="mt-4">
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1 col-12 mt-4"
                          onClick={() => setStep("makePayment")}
                        >
                          Make Payment
                        </Button>
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1 col-12 mt-4"
                          onClick={() => setStep("orders")}
                        >
                          Orders
                        </Button>
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1 col-12 mt-4"
                          onClick={() => setStep("statements")}
                        >
                          View Statements
                        </Button>
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1 col-12 mt-4"
                          onClick={() => setModal(true)}
                        >
                          Automatic Payments Settings
                        </Button>
                      </div>
                    </div>
                    {/* send Modal */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(ProductOrder));
