import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
// import { colourOptions } from "./data.js";
import { default as ReactSelect } from "react-select";
// import "./styles.css";
import { components } from "react-select";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import "./styles.css";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    //   height: `${width > 1400 ? "3.5rem" : ""}`,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: null,
      color: isDisabled ? "#ccc" : isSelected ? "black" : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":hover": {
        ...styles[":hover"],
        backgroundColor: "rgba(77, 6, 165, 0.1)",
      },
    };
  },
};

export default function DropDownMultiSelect({ options, checkFunction }) {
  const [optionSelected, setOptionSelected] = useState(null);
  const [colourOptions, setColourOptions] = useState([]);

  //   const handleChange = (selected) => {
  //     setOptionSelected(selected);
  //     checkFunction(selected);
  //   };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div className="d-flex">
            <Form.Check
              type="checkbox"
              defaultChecked={props.data.viewColumn == false ? false : true}
              onChange={(e) => checkFunction(props)}
            />{" "}
            {/* <input
            type="checkbox"
            checked={props.isSelected}
            className="input-check"
            onChange={() => null}
          />{" "} */}
            <>&nbsp;</>
            <label style={{ marginTop: "3px" }}>{props.label}</label>
          </div>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    options.forEach((element) => {
      element.value = element.title;
      element.label = element.title;
    });
    setColourOptions(options);
  }, [options]);

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <>
          <div
            className="multiSelectITP"
            style={{
              paddingLeft: "5px",
              color: "#6e6e6e",
              display: "flex",
              alignItems: "center",
            }}
          >
            Select Columns
          </div>
          {children[1]}
        </>
      </components.Control>
    );
  };
  return (
    <span
      className="d-inline-block"
      data-toggle="popover"
      data-trigger="focus"
      data-content="Please selecet account(s)"
    >
      <ReactSelect
        className="form-arrow"
        options={colourOptions}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          Control,
        }}
        // onChange={(e)=>checkFunction(e)}
        // onChange={(e)=>checkFunction(e)}
        allowSelectAll={true}
        value={optionSelected}
        styles={colourStyles}
        isClearable={false}
      />
    </span>
  );
}
