import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

/// Images
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";

import ser from "../../../images/serv.png";
// import CRM from "../../../images/CRM.png";
// import office from "../../../images/office.png";
import petitionGet from "../../../services/petitionGet";
import { sendToastError } from "../../../utils/toastAlert";
import MsgBox from "./MsgBox";

const Chat = ({ PerfectScrollbar, toggleChatBox, toggleTab }) => {
  const [openMsg, setOpenMsg] = useState(false);

  // const title = "CRM360";

  // function img(info) {
  //   const imagenes = [ser, office];
  //   //console.log(info.product);

  //   switch (info.product.item_type) {
  //     case (info.product.item_type = "itp_voice"):
  //       return imagenes[0];
  //     case (info.product.item_type = "office365"):
  //       return imagenes[1];
  //   }
  // }

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    setContacts([]);
    petitionGet("services")
      .then((result) => {
        let apps = [];
        let json = require("../../../config.json");
        let linkPbx = json.prod ? json.pbx.prod : json.pbx.dev;
        let token = JSON.parse(localStorage.getItem("userDetails")).token;

        result.data.result.forEach((element) => {
          if (element.item_type === "office365") {
            element.route = `https://app.dev.crm360.app/userdash/login/?token=${token}`;
            element.prod_description = "Microsoft 365 Business Standard";
          } else if (element.item_type === "itp_voice") {
            element.route = `${linkPbx}/login?token=${token}&&v2=${element.api_id}`;
            element.prod_description = "VOICE360";
          }

          apps.push(element);
        });
        setContacts(result.data.result);
      })
      .catch((err) => sendToastError());
  }, []);

  // const cmrRender = (data) => {
  //   if (data.product.item_type === "itp_voice") {
  //     return (
  //       <li
  //         onClick={() => {
  //           window.location.href = `https://app.dev.crm360.app/userdash/login?token=${token}&api_id=${data.api_id}`;
  //         }}
  //         key={data.api_id}
  //         className="active dlab-chat-user"
  //       >
  //         <div className="d-flex bd-highlight">
  //           <div className="img_cont">
  //             <img src={CRM} className="rounded-circle user_img" alt="" />
  //             <span className="online_icon"></span>
  //           </div>
  //           <div className="user_info">
  //             <span>{title}</span>
  //             <p>Service id: {data.api_id}</p>
  //           </div>
  //         </div>
  //       </li>
  //     );
  //   }
  // };
  // const voice360 = (data) => {
  //   if (data.product.item_type === "itp_voice") {
  //     return (
  //       <li
  //         onClick={() => {
  //           window.location.href = data.route;
  //         }}
  //         key={data.api_id}
  //         className="active dlab-chat-user"
  //       >
  //         <div className="d-flex bd-highlight">
  //           <div className="img_cont">
  //             <img src={ser} className="rounded-circle user_img" alt="" />
  //             <span className="online_icon"></span>
  //           </div>
  //           <div className="user_info">
  //             <span>{"VOICE360"}</span>
  //             <p>Service id: {data.api_id}</p>
  //           </div>
  //         </div>
  //       </li>
  //     );
  //   }
  // };

  return (
    <div
      className={`tab-pane fade  ${toggleTab === "chat" ? "active show" : ""}`}
      id="chat"
      role="tabpanel"
      key="chat"
    >
      <div
        className={`card mb-sm-3 mb-md-0 contacts_card dlab-chat-user-box ${
          openMsg ? "d-none" : ""
        }`}
      >
        <div className="card-header chat-list-header text-center">
          <div className="col-12 text-center">
            <h6 className="mb-1">App List</h6>
          </div>
        </div>
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dlab-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }`}
          id="DZ_W_Contacts_Body"
        >
          <ul className="contacts">
            {contacts.map((contact) => {
              if (contact.product.item_type === "itp_voice") {
                return (
                  <li
                    onClick={() => {
                      window.location.href = contact.route;
                    }}
                    key={contact.api_id}
                    className="active dlab-chat-user"
                  >
                    <div className="d-flex bd-highlight">
                      <div className="img_cont">
                        <img
                          src={ser}
                          className="rounded-circle user_img"
                          alt=""
                        />
                        <span className="online_icon"></span>
                      </div>
                      <div className="user_info">
                        <span>{"VOICE360"}</span>
                        <p>Service id: {contact.api_id}</p>
                      </div>
                    </div>
                  </li>
                );
              } else {
                return "";
              }
            })}
          </ul>
        </PerfectScrollbar>
      </div>
      <MsgBox
        avatar1={avatar1}
        avatar2={avatar2}
        openMsg={openMsg}
        PerfectScrollbar={PerfectScrollbar}
        offMsg={() => setOpenMsg(false)}
      />
    </div>
  );
};

export default Chat;
