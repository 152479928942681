import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  const params = new URL(document.location).searchParams;
  const redirect = params.get("redirect");
  history.push(`/login${redirect ? `?redirect=${redirect}` : ""}`);
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history, setLoading) {
  return (dispatch) => {
    setLoading(true);
    login(email, password)
      .then((response) => {
        formatError(response);
        saveTokenInLocalStorage(response.data.result);
        runLogoutTimer(dispatch, 1000000, history);
        dispatch(loginConfirmedAction(response.data.result));

        const params = new URL(document.location).searchParams;
        const redirect = params.get("redirect");

        if (!redirect) history.push("/apps");

        // if (redirect) console.log("___");
        // // window.location.href = `${redirect}/login?token=${response.data.result.access_token}`;
        // else history.push("/apps");
      })
      .catch((error) => {
        console.log(error.response.data);
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
        setLoading(false);
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
