import React, {useState, useRef, useEffect} from "react";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import petitionGet from "../../../../../services/petitionGet";
import {sendToastError} from "../../../../../utils/toastAlert";

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
function Tickets({setStep, profile}) {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);

  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    //chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");

  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let {id} = profile.auth.auth;

    setMessages([]);
    petitionGet("getTickets", {id})
      .then((result) => {
        setMessages(result.data.result.data);
      })
      .catch((err) => sendToastError());
  }, []);

  console.log(messages);

  return (
    <div className="row">
      <div className="col-xl-12 col-lg-12">
        <div className="email-right-box">
          <div role="toolbar" className="toolbar ms-1 ms-sm-0">
            <div className="btn-group mb-1 me-1 ms-1">
              <div className="form-check custom-checkbox">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="checkbox1"
                  onClick={() => chackboxFun("all")}
                />
                <label className="form-check-label" htmlFor="checkbox1"></label>
              </div>
            </div>
            <div className="btn-group mb-1">
              <button className="btn btn-primary light px-3" type="button">
                <i className="ti-reload"></i>
              </button>
            </div>
            <Dropdown className="btn-group mb-1">
              <Dropdown.Toggle
                aria-expanded="false"
                data-toggle="dropdown"
                className="btn btn-primary px-3 light dropdown-toggle ms-1"
                type="button"
              >
                More <span className="caret"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item to="/email-inbox" className="dropdown-item">
                  Mark as Unread
                </Dropdown.Item>
                <Dropdown.Item to="/email-inbox" className="dropdown-item">
                  Add to Tasks
                </Dropdown.Item>
                <Dropdown.Item to="/email-inbox" className="dropdown-item">
                  Add Star
                </Dropdown.Item>
                <Dropdown.Item to="/email-inbox" className="dropdown-item">
                  Mute
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/** Single Message */}
          <div className="email-list mt-3">
            {messages.map((message) => (
              <div key={message.id} className="message">
                <div>
                  <div className="d-flex message-single">
                    <div className="ps-1 align-self-center">
                      <div className="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onClick={() => chackboxFun()}
                          id={`checkbox${message.pk + 1}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkbox2"
                        />
                      </div>
                    </div>
                    <div className="ms-2">
                      <button className="border-0 bg-transparent align-middle p-0">
                        <i className="fa fa-star" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <Link
                    onClick={() => setStep(3)}
                    className="col-mail col-mail-2"
                  >
                    <div className="subject">{message.subject || ""}</div>
                    <div className="date">
                      {new Date(message.createdTime).toLocaleDateString() || ""}
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="row mt-4">
            <div className="col-12 ps-3">
              <nav>
                <ul className="pagination pagination-gutter pagination-primary pagination-sm no-bg">
                  <li className="page-item page-indicator">
                    <Link
                      className="page-link"
                      to="/email-inbox"
                      onClick={() =>
                        activePag.current > 0 && onClick(activePag.current - 1)
                      }
                    >
                      <i className="la la-angle-left"></i>
                    </Link>
                  </li>
                  {paggination.map((number, i) => (
                    <li
                      key={i}
                      className={`page-item  ${
                        activePag.current === i ? "active" : ""
                      } `}
                      onClick={() => onClick(i)}
                    >
                      <Link className="page-link" to="/email-inbox">
                        {number}
                      </Link>
                    </li>
                  ))}

                  <li className="page-item page-indicator">
                    <Link
                      className="page-link"
                      to="/email-inbox"
                      onClick={() =>
                        activePag.current + 1 < paggination.length &&
                        onClick(activePag.current + 1)
                      }
                    >
                      <i className="la la-angle-right"></i>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-3 offset-lg-9 mt-4">
        <button
          onClick={() => setStep(2)}
          className="btn btn-primary btn-block"
        >
          Create Ticket
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(Tickets));
