import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../../context/ThemeContext";
import petitionPatch from "../../../../../services/petitionPatch";
import { useDispatch } from "react-redux";
import { loginConfirmedAction } from "../../../../../store/actions/AuthActions";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import {
  sendToastError,
  sendToastSuccess,
} from "../../../../../utils/toastAlert";
import Select from "react-select";

const ProfileSettings = ({ props }) => {
  const { changeBackground } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [dataSavedInLocalStorage, setDataSavedInLocalStorage] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const dispatch = useDispatch();

  const options = [
    {
      label: "Select Option",
      value: "",
      isDisabled: true,
    },
    {
      label: "US/Alaska GMT-9:00",
      value: "US/Alaska",
    },
    {
      label: "US/Arizona GMT-7:00",
      value: "US/Arizona",
    },
    {
      label: "US/Central GMT-6:00",
      value: "US/Central",
    },
    {
      label: "US/Eastern GMT-5:00",
      value: "US/Eastern",
    },
    {
      label: "US/Hawaii GMT-10:00",
      value: "US/Hawaii",
    },
    {
      label: "US/Indiana-Starke GMT-6:00",
      value: "US/Indiana-Starke",
    },
    {
      label: "US/Michigan GMT-5:00",
      value: "US/Michigan",
    },
    {
      label: "US/Mountain GMT-7:00",
      value: "US/Mountain",
    },
    {
      label: "US/Pacific GMT-8:00",
      value: "US/Pacific",
    },
    {
      label: "US/Samoa GMT-11:00",
      value: "US/Samoa",
    },
    {
      label: "Asia/Kolkata GMT+5:30",
      value: "Asia/Kolkata",
    },
    {
      label: "Asia/Shanghai GMT+8:00",
      value: "Asia/Shanghai",
    },
    {
      label: "Asia/Tokyo GMT+9:00",
      value: "Asia/Tokyo",
    },
    {
      label: "Asia/Dubai GMT+4:00",
      value: "Asia/Dubai",
    },
    {
      label: "Asia/Hong_Kong GMT+8:00",
      value: "Asia/Hong_Kong",
    },
    {
      label: "Asia/Singapore GMT+8:00",
      value: "Asia/Singapore",
    },
    {
      label: "Asia/Seoul GMT+9:00",
      value: "Asia/Seoul",
    },
    {
      label: "Europe/Berlin GMT+2:00",
      value: "Europe/Berlin",
    },
    {
      label: "Europe/Madrid GMT+2:00",
      value: "Europe/Madrid",
    },
    {
      label: "Europe/Rome GMT+2:00",
      value: "Europe/Rome",
    },
    {
      label: "Europe/London GMT+0:00",
      value: "Europe/London",
    },
    {
      label: "Europe/Paris GMT+2:00",
      value: "Europe/Paris",
    },
    {
      label: "Europe/Moscow GMT+4:00",
      value: "Europe/Moscow",
    },
    {
      label: "America/Sao_Paulo GMT-3:00",
      value: "America/Sao_Paulo",
    },
    {
      label: "America/Bogota GMT-5:00",
      value: "America/Bogota",
    },
    {
      label: "America/Toronto GMT-5:00",
      value: "America/Toronto",
    },
    {
      label: "America/Mexico_City GMT-6:00",
      value: "America/Mexico_City",
    },
    {
      label: "Africa/Cairo GMT+3:00",
      value: "Africa/Cairo",
    },
    {
      label: "Africa/Johannesburg GMT+2:00",
      value: "Africa/Johannesburg",
    },
    {
      label: "Australia/Sydney GMT+10:00",
      value: "Australia/Sydney",
    },
    {
      label: "Australia/Perth GMT+8:00",
      value: "Australia/Perth",
    },
    {
      label: "Pacific/Auckland GMT+12:00",
      value: "Pacific/Auckland",
    },
    {
      label: "UTC GMT+0:00",
      value: "UTC",
    },
  ];

  const [form, setForm] = useState({
    firstname: dataSavedInLocalStorage?.firstname || "",
    lastname: dataSavedInLocalStorage?.lastname || "",
    phone: dataSavedInLocalStorage?.phone || "",
    mobile: dataSavedInLocalStorage?.mobile || "",
    fax: dataSavedInLocalStorage?.fax || "",
    ui_color_mode: dataSavedInLocalStorage?.ui_color_mode || "light",
    time_zone: dataSavedInLocalStorage?.time_zone || "",
  });

  const [valueTimeZone, setValueTimeZone] = useState(null);

  const handleSubmitSave = (e) => {
    e.preventDefault()
    setLoading(true);

    petitionPatch("myprofile", form)
      .then(({ data: result }) => {
        localStorage.setItem(
          "userDetails",
          JSON.stringify({ ...dataSavedInLocalStorage, ...result?.result })
        );
        dispatch(loginConfirmedAction({ ...props.profile.auth.auth, ...form }));
        setDataSavedInLocalStorage({
          ...dataSavedInLocalStorage,
          ...result?.result,
        });
        sendToastSuccess();
      })
      .catch((err) => sendToastError())
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (dataSavedInLocalStorage?.ui_color_mode === "dark") {
      changeBackground({ value: "dark", label: "Dark" });
    }
    if (dataSavedInLocalStorage?.ui_color_mode === "light") {
      changeBackground({ value: "light", label: "Light" });
    }

  }, [dataSavedInLocalStorage]);

  useEffect(() => {
    let dataTimeZone = options.find(
      (element) => element.value === dataSavedInLocalStorage?.time_zone
    );

    if (dataTimeZone) setValueTimeZone(dataTimeZone);
  }, [])

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body">
            <div className="settings-form">
              <form onSubmit={handleSubmitSave}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="firstname" className="form-label">
                      First name
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      onChange={handleChange}
                      value={form.firstname}
                      name="firstname"
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="lastname" className="form-label">
                      Last name
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      onChange={handleChange}
                      value={form.lastname}
                      name="lastname"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      onChange={handleChange}
                      value={form.phone}
                      name="phone"
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="mobile" className="form-label">
                      Mobile
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      onChange={handleChange}
                      value={form.mobile}
                      name="mobile"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="fax" className="form-label">
                      Fax
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      onChange={handleChange}
                      value={form.fax}
                      name="fax"
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="ui_color_mode" className="form-label">
                      Theme
                    </label>
                    <select
                      className="form-select"
                      style={{ height: "3rem" }}
                      onChange={handleChange}
                      value={form.ui_color_mode}
                      name="ui_color_mode"
                    >
                      <option value="light"> Light </option>
                      <option value="dark"> Dark </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">Time Zone</label>
                    <Select
                      onChange={(e) => {
                        setForm({ ...form, time_zone: e.value });
                        setValueTimeZone(e);
                      }}
                      className="basic-single"
                      placeholder="Select Option"
                      classNamePrefix="select"
                      value={valueTimeZone}
                      isSearchable={true}
                      name="time_zone"
                      options={options}
                    />
                  </div>
                </div>
                <button
                  disabled={loading}
                  className="btn btn-primary"
                  type="submit"
                >
                  {loading && (
                    <Spinner
                      style={{ marginRight: "0.5em" }}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
