import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Spinner, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import petitionGet from "../../../../services/petitionGet";
import { sendToastError, sendToastSuccess } from "../../../../utils/toastAlert";
import petitionPost from "../../../../services/petitionPost";
import petitionDelete from "../../../../services/petitionDelete";
import petitionPatch from "../../../../services/petitionPatch";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import "./../editUserModal.css";

function AddCardModal({
  reloadData,
  setModal,
  setInfoModal,
  infoModal,
  modal,
}) {
  const dataLocalStorage = JSON.parse(localStorage.getItem("userDetails"));
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState([]);
  const [checkValue, setCheckValue] = useState(false);
  const [voiceLicence, setVoiceLicence] = useState(null);
  const [licenceID, setLicenceID] = useState(null);
  const [validation, setValidation] = useState(false);

  const changeCheck = (e) => {
    setCheckValue(e.target.checked);
  };

  const show = () => {
    return (
      <Form.Check
        className="licenseChecked"
        checked={checkValue}
        type="checkbox"
        onChange={(e) => {
          changeCheck(e);
        }}
      />
    );
  };

  const [activeToggle, setActiveToggle] = useState("profile");
  const [form, setForm] = useState({
    firstname: infoModal.firstname,
    lastname: infoModal.lastname,
    email: infoModal.email,
    mobile: infoModal.mobile,
    admin_group: infoModal.admin_group,
    billing_group: infoModal.billing_group,
    technical_group: infoModal.technical_group,
    time_zone: infoModal.time_zone,
  });

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmitSave = () => {
    if (activeToggle === "manageLicenses") {
      handleSubmitAddLicense();
    } else if (activeToggle === "profile") {
      changeDataUser();
    }
  };

  const handleSubmitAddLicense = () => {
    setLoading(true);

    if (checkValue) {
      petitionPost("addLicenses", {
        contact_id: infoModal.pk,
        data: { service_id: voiceLicence },
      })
        .then((result) => {
          sendToastSuccess();
        })
        .catch((err) => sendToastError())
        .finally(() => setLoading(false));
    } else {
      if (licenceID) {
        petitionDelete("licencesDelete", {
          contact_id: infoModal.pk,
          license_id: licenceID,
        })
          .then((result) => {
            sendToastSuccess();
          })
          .catch((err) => sendToastError())
          .finally(() => setLoading(false));
      }
    }
  };

  const changeDataUser = () => {
    const validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (validEmail.test(form.email)) {
      setLoading(true);
      const dataForm = {
        firstname: form.firstname,
        lastname: form.lastname,
        email: form.email,
        mobile: form.mobile,
        admin_group:
          form.admin_group === "true" || form.admin_group === true
            ? true
            : false,
        billing_group:
          form.billing_group === "true" || form.billing_group === true
            ? true
            : false,
        technical_group:
          form.technical_group === "true" || form.technical_group === true
            ? true
            : false,
        time_zone: form.time_zone,
      };

      petitionPatch("changeDataUsers", {
        pk: infoModal.pk,
        data: dataForm,
      })
        .then(({ data: result }) => {
          let dataSave = dataLocalStorage;
          dataSave.billing_group = result.result.billing_group;

          localStorage.setItem("userDetails", JSON.stringify({ ...dataSave }));
          setLoading(false);
          setValidation(false);
          reloadData();
          setModal(false);
          setInfoModal(null);
          sendToastSuccess();
        })
        .catch((error) => {
          console.log(error);
          sendToastError();
        });
    } else {
      setValidation(true);
    }
  };

  useEffect(() => {
    const petititions = async () => {
      let licenses;
      await petitionGet("contactLicenses", infoModal.pk)
        .then(({ data: { result } }) => {
          licenses = result;
          setService(licenses);
          let filter = licenses.filter((element) => {
            return element.item_type === "itp_voice";
          });
          setCheckValue(filter.length ? true : false);
          if (filter.length) setLicenceID(filter[0].pk);
        })
        .catch((error) => {
          console.log(error);
        });

      petitionGet("services")
        .then(({ data: { result } }) => {
          let filter = result.filter((element) => {
            return element.item_type === "itp_voice";
          });
          setVoiceLicence(filter[0].pk);
          let serv = [];
          result.forEach((service) => {
            let findLicense = licenses.find(
              (license) => license.service_id === service.pk
            );

            if (findLicense) serv.push({ ...service, available: false });
            else serv.push({ ...service, available: true });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    petititions();
  }, []);

  return (
    <Modal className="fade" show={modal} centered size={"lg"}>
      <Modal.Header>
        <Modal.Title>User</Modal.Title>
        <Button
          onClick={() => {
            setModal(false);
            setInfoModal(null);
          }}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 ">
            <div className="d-flex row flex-wrap custom-tab-1">
              <Container fluid>
                <Nav
                  tabs
                  className="nav-tabs-custom  flex-column flex-md-row  text-center  "
                >
                  <NavItem key="1" className="pt-3 pt-md-0">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeToggle === "profile",
                      })}
                      onClick={() => setActiveToggle("profile")}
                    >
                      <span style={{ color: "#5f5f5f" }}>Profile</span>
                    </NavLink>
                  </NavItem>
                  <NavItem key="2" className="pt-3 pt-md-0">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeToggle === "manageLicenses",
                      })}
                      onClick={() => setActiveToggle("manageLicenses")}
                    >
                      <span style={{ color: "#5f5f5f" }}>Manage Licenses</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeToggle}>
                  <TabPane key="1" tabId="profile" className="p-3">
                    <div className="row" style={{ paddingTop: "10px" }}>
                      <div className="form-group mb-3 col-6">
                        <label htmlFor="firstname" className="form-label">
                          First name
                        </label>
                        <input
                          onChange={onChangeForm}
                          name="firstname"
                          id="firstname"
                          type="text"
                          defaultValue={
                            infoModal.firstname ? `${infoModal.firstname}` : ""
                          }
                          className={`form-control`}
                        />
                      </div>
                      <div className="form-group mb-3 col-6">
                        <label htmlFor="lastname" className="form-label">
                          Last name
                        </label>
                        <input
                          onChange={onChangeForm}
                          name="lastname"
                          id="lastname"
                          type="text"
                          defaultValue={
                            infoModal.lastname ? `${infoModal.lastname}` : ""
                          }
                          className={`form-control`}
                        />
                      </div>
                      <div className="form-group mb-3 col-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          onChange={onChangeForm}
                          name="email"
                          id="email"
                          type="text"
                          defaultValue={
                            infoModal.email ? `${infoModal.email}` : ""
                          }
                          className={`form-control ${
                            validation ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                      <div className="form-group mb-3 col-6">
                        <label htmlFor="mobile" className="form-label">
                          Mobile
                        </label>
                        <input
                          onChange={onChangeForm}
                          name="mobile"
                          id="mobile"
                          type="text"
                          defaultValue={
                            infoModal.mobile ? `${infoModal.mobile}` : ""
                          }
                          className={`form-control`}
                        />
                      </div>
                      <div className="form-group mb-3 col-6">
                        <label htmlFor="admin_group" className="form-label">
                          Admin Group
                        </label>
                        <select
                          onChange={onChangeForm}
                          defaultValue={
                            infoModal.admin_group ? "true" : "false"
                          }
                          name="admin_group"
                          id="admin_group"
                          className="form-control"
                        >
                          <option value={true}> True </option>
                          <option value={false}> False </option>
                          Select one...
                        </select>
                      </div>
                      <div className="form-group mb-3 col-6">
                        <label htmlFor="billing_group" className="form-label">
                          Billing Group
                        </label>
                        <select
                          onChange={onChangeForm}
                          defaultValue={
                            infoModal.billing_group ? "true" : "false"
                          }
                          name="billing_group"
                          id="billing_group"
                          className="form-control"
                        >
                          <option value={true}> True </option>
                          <option value={false}> False </option>
                          Select one...
                        </select>
                      </div>
                      <div className="form-group mb-3 col-6">
                        <label htmlFor="technical_group" className="form-label">
                          Technical Group
                        </label>
                        <select
                          onChange={onChangeForm}
                          defaultValue={
                            infoModal.technical_group ? "true" : "false"
                          }
                          name="technical_group"
                          id="technical_group"
                          className="form-control"
                        >
                          <option value={true}> True </option>
                          <option value={false}> False </option>
                          Select one...
                        </select>
                      </div>
                      <div className="form-group mb-3 col-6">
                        <label htmlFor="time_zone" className="form-label">
                          Timezone
                        </label>
                        <select
                          onChange={onChangeForm}
                          defaultValue={
                            infoModal.time_zone ? infoModal.time_zone : ""
                          }
                          name="time_zone"
                          id="time_zone"
                          className="form-control"
                        >
                          <option value="US/Alaska">US/Alaska GMT-9:00</option>

                          <option value="US/Arizona">
                            US/Arizona GMT-7:00
                          </option>
                          <option value="US/Central">
                            US/Central GMT-6:00
                          </option>
                          <option value="US/Eastern">
                            US/Eastern GMT-5:00
                          </option>
                          <option value="US/Hawaii">US/Hawaii GMT-10:00</option>
                          <option value="US/Indiana-Starke">
                            US/Indiana-Starke GMT-6:00
                          </option>
                          <option value="US/Michigan">
                            US/Michigan GMT-5:00
                          </option>
                          <option value="US/Mountain">
                            US/Mountain GMT-7:00
                          </option>
                          <option value="US/Pacific">
                            US/Pacific GMT-8:00
                          </option>
                          <option value="US/Samoa">US/Samoa GMT-11:00</option>

                          <option value="Asia/Hong_Kong">
                            Asia/Hong_Kong GMT+8:00
                          </option>
                          <option value="Europe/Berlin">
                            Europe/Berlin GMT+1:00
                          </option>
                          <option value="Europe/Madrid">
                            Europe/Madrid GMT+1:00
                          </option>
                          <option value="Europe/Rome">
                            Europe/Rome GMT+1:00
                          </option>
                        </select>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane key="2" tabId="manageLicenses" className="p-3">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Service</th>
                          <th>{"License Enabled"}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody id="customers">
                        <tr className="btn-reveal-trigger">
                          <td>
                            <div className="media d-flex align-items-center">
                              <div className="media-body">
                                <h5 className="mb-0 fs--1">
                                  VOICE360 / CRM360
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td>{show()}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </TabPane>
                </TabContent>
              </Container>
            </div>
          </div>
        </div>
        {/* <div className="row" style={{ paddingTop: "10px" }}>
          <div className="form-group mb-3 col-6">
            <label htmlFor="firstname" className="form-label">
              First name
            </label>
            <input
              onChange={onChangeForm}
              name="firstname"
              id="firstname"
              type="text"
              defaultValue={infoModal.firstname ? `${infoModal.firstname}` : ""}
              className={`form-control`}
            />
          </div>
          <div className="form-group mb-3 col-6">
            <label htmlFor="lastname" className="form-label">
              Last name
            </label>
            <input
              onChange={onChangeForm}
              name="lastname"
              id="lastname"
              type="text"
              defaultValue={infoModal.lastname ? `${infoModal.lastname}` : ""}
              className={`form-control`}
            />
          </div>
          <div className="form-group mb-3 col-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              onChange={onChangeForm}
              name="email"
              id="email"
              type="text"
              defaultValue={infoModal.email ? `${infoModal.email}` : ""}
              className={`form-control ${validation ? "is-invalid" : ""}`}
            />
          </div>
          <div className="form-group mb-3 col-6">
            <label htmlFor="mobile" className="form-label">
              Mobile
            </label>
            <input
              onChange={onChangeForm}
              name="mobile"
              id="mobile"
              type="text"
              defaultValue={infoModal.mobile ? `${infoModal.mobile}` : ""}
              className={`form-control`}
            />
          </div>
          <div className="form-group mb-3 col-6">
            <label htmlFor="admin_group" className="form-label">
              Admin Group
            </label>
            <select
              onChange={onChangeForm}
              defaultValue={infoModal.admin_group ? "true" : "false"}
              name="admin_group"
              id="admin_group"
              className="form-control"
            >
              <option value={true}> True </option>
              <option value={false}> False </option>
              Select one...
            </select>
          </div>
          <div className="form-group mb-3 col-6">
            <label htmlFor="billing_group" className="form-label">
              Billing Group
            </label>
            <select
              onChange={onChangeForm}
              defaultValue={infoModal.billing_group ? "true" : "false"}
              name="billing_group"
              id="billing_group"
              className="form-control"
            >
              <option value={true}> True </option>
              <option value={false}> False </option>
              Select one...
            </select>
          </div>
          <div className="form-group mb-3 col-6">
            <label htmlFor="technical_group" className="form-label">
              Technical Group
            </label>
            <select
              onChange={onChangeForm}
              defaultValue={infoModal.technical_group ? "true" : "false"}
              name="technical_group"
              id="technical_group"
              className="form-control"
            >
              <option value={true}> True </option>
              <option value={false}> False </option>
              Select one...
            </select>
          </div>
          <div className="form-group mb-3 col-6">
            <label htmlFor="time_zone" className="form-label">
              Timezone
            </label>
            <select
              onChange={onChangeForm}
              defaultValue={infoModal.time_zone ? infoModal.time_zone : ""}
              name="time_zone"
              id="time_zone"
              className="form-control"
            >
              <option value="US/Alaska">US/Alaska GMT-9:00</option>

              <option value="US/Arizona">US/Arizona GMT-7:00</option>
              <option value="US/Central">US/Central GMT-6:00</option>
              <option value="US/Eastern">US/Eastern GMT-5:00</option>
              <option value="US/Hawaii">US/Hawaii GMT-10:00</option>
              <option value="US/Indiana-Starke">
                US/Indiana-Starke GMT-6:00
              </option>
              <option value="US/Michigan">US/Michigan GMT-5:00</option>
              <option value="US/Mountain">US/Mountain GMT-7:00</option>
              <option value="US/Pacific">US/Pacific GMT-8:00</option>
              <option value="US/Samoa">US/Samoa GMT-11:00</option>

              <option value="Asia/Hong_Kong">Asia/Hong_Kong GMT+8:00</option>
              <option value="Europe/Berlin">Europe/Berlin GMT+1:00</option>
              <option value="Europe/Madrid">Europe/Madrid GMT+1:00</option>
              <option value="Europe/Rome">Europe/Rome GMT+1:00</option>
            </select>
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setModal(false);
            setInfoModal(null);
          }}
          className="btn btn-primary"
        >
          Close
        </Button>
        <button
          onClick={() => handleSubmitSave(service)}
          disabled={loading}
          className="btn btn-primary mx-3"
          type="submit"
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(AddCardModal));
